.bvnV2 {
  #page-container {
    max-width: 850px;
    overflow: hidden;
    margin: 0 auto;
  }

  .page-container {
    position: relative;
    z-index: 1;
    display: flex;
    transition: transform 0.6s cubic-bezier(0.65, 0, 0.35, 1);
    will-change: transform;
  }

  .page-container .page {
    flex: 0 0 100%;
    overflow-y: auto;
  }

  .page-content .hero {
    background: var(--secondaryColor);
  }

  .magasin-icon-close-div a {
    text-align: right !important;
  }

  .page-content .content-wrap {
    padding: 0px 16px;
  }

  .inner-content-wrap {
    padding: 0px 0px 0px 14px;
    margin-right: auto;
  }

  #mid-section {
    background: var(--secondaryColor);
  }

  .page-content .page-header {
    height: 60px;
    position: relative;
  }

  .page-content .page-header .icon {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
  }

  .page-content .page-header .icon-arrow-left {
    left: -16px;
  }

  .page-content .page-header h1 {
    text-align: center;
    color: var(--primaryAccent) !important;
    height: 60px;
    line-height: 60px !important;
    font-size: 22px;
    font-weight: bold;
  }

  .btn.btn-primary:disabled {
    opacity: 0.5;
  }

  /*
    =============
    Page specific styles
    =============
    */

  #main-header,
  #main-footer {
    display: none;
  }

  .et_pb_section {
    padding: 0;
  }

  body > img:first-child {
    display: none;
  }

  .page-container {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .user-img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 100%;
  }

  .user-img img {
    width: 100%;
  }

  .card.card-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    background-image: linear-gradient(to bottom, #3a4581, #28315c);
  }

  .card.card-user * {
    color: var(--primaryAccent);
  }

  .card.card-user .card-info {
    flex: 1;
    font-weight: bold;
    padding: 0 16px;
  }

  .card.card-user .points {
    text-align: center;
  }

  .card-user-wrap .points {
    min-width: 70px;
    background: var(--partnerTxtColor7) !important;
    border-radius: 21px !important;
    padding: 10px 13px !important;
  }

  .card-user-wrap .card-client {
    background: var(--secondaryColor) !important;
  }

  .card.card-user .points .point-number {
    font-size: 22px;
    font-weight: bold;
  }

  .card-user-wrap {
    margin-bottom: 30px;
  }

  .magasin-init-text-div {
    margin: 60px auto 32px auto;
    width: 302px;
    height: 29px;
  }

  .magasin-init-text {
    font-size: 22px;
    font-weight: bold;
    color: white;
  }

  .magasin-init-btn-div {
    text-align: center;
    margin-bottom: 32px;
  }

  .magasin-init-btn {
    height: 110px;
    width: 296px;
    border-radius: 42px;
    border: solid 2px var(--partnerBorderColor1);
    background: var(--secondaryColor);
    color: white;
    font-size: 22px;
  }

  .magasin-home {
    background: var(--secondaryColor);
  }

  .magasin-icon-close-div {
    margin-top: 19px;
  }

  .magasin-icon-close-div a {
    text-align: right;
    background: var(--secondaryColor);
  }

  .magasin-icon-close-div a span {
    width: 40px !important;
    height: 15px !important;
    background-size: cover !important;
  }

  .magasin-home .shop-icon {
    text-align: center;
    margin: 20px auto 10px auto;
    padding: 10px;
    color: var(--primaryAccent);
    position: relative;
  }

  .shop-icon-div {
    margin-top: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .shop-icon-text-div {
    margin-top: 35px;
    margin-bottom: 32px;
  }

  .magasin-home-text-12 {
    font-size: 12px !important;
  }

  .back-to-init-div {
    margin: 32px 10px 0px auto;
  }

  .back-to-init-btn {
    color: white !important;
    text-decoration: underline;
    font-weight: 300;
  }

  .radio-check-div {
    margin-top: 32px;
  }

  .check-main-block {
    margin-left: auto;
    margin-right: auto;
  }

  .checklist-checkboxtext {
    width: 245px;
    color: white !important;
    font-weight: 300;
  }

  .consent-btn-div {
    text-align: center;
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .consent-btn {
    width: 296px;
    height: 55px;
    border: 1px solid #ffffff;
    background: var(--secondaryColor);
    border-radius: 42px;
    color: white;
  }

  .magasin-home .shop-icon img {
    height: 175px;
  }

  .magasin-home .shop-icon .icon {
    width: 60px;
    height: 60px;
  }

  .magasin-home .shop-icon .icon-hamburger {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 14'%3E%3Cdefs/%3E%3Cpath fill='%23ffffff' d='M0 14v-2h10v2zm0-6V6h16v2zm0-6V0h16v2z'/%3E%3C/svg%3E");
  }

  .magasin-home .shop-icon h2 {
    margin: 5px !important;
    color: white !important;
    font-size: 22px;
    padding: 0;
  }

  .pl-10 {
    padding-left: 10px;
  }

  .magasin-home .hero {
    margin-bottom: -50px;
    padding-bottom: 90px;
  }

  #magasinTransaction {
    background: var(--secondaryColor);
  }

  #magasinSummary {
    background: var(--secondaryColor);
  }

  .magasin-transaction .page-content > .content-wrap {
    padding-bottom: 200px;
  }

  .magasin-transaction .hero {
    margin-bottom: -50px;
    padding-bottom: 48px;
  }

  .magasin-transaction .hero .page-header {
    margin-bottom: 30px;
  }

  .transaction-amount-wrap {
    display: flex;
    flex-flow: column nowrap;
    background: #c7363c !important;
    padding: 16px 25px;
    margin: 0 calc(var(--basePadding) * -1);
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1;
  }

  .transaction-amount-wrap .transaction-amount {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    color: var(--primaryAccent);
  }

  .transaction-amount-wrap .transaction-amount .form-control {
    width: 100px;
    padding: 0 15px;
    text-align: center;
    border-radius: 50px;
  }

  .transaction-amount-wrap .transaction-proceed {
    margin: 16px 0;
    display: none;
  }

  .transaction-amount-wrap.expand .transaction-proceed {
    display: block;
  }

  .transaction-amount-wrap .transaction-proceed .btn .icon {
    width: 20px;
    height: 16px;
    margin-left: 10px;
    vertical-align: middle;
  }

  .magasin-transaction .card.card-offer .btn-secondary {
    border-radius: 14px;
  }

  .magasin-transaction .card.card-offer .btn-secondary.active {
    color: #56c596;
    border-color: #56c596;
    background-color: unset !important;
  }

  .card-summary {
    padding: 16px 24px !important;
  }

  .magasin-summary .page-content .page-header h1 {
    text-align: left;
    padding-left: 80px;
  }

  .magasin-summary .page-content > .content-wrap {
    padding-bottom: 300px;
  }

  .sheet-summary {
    position: fixed;
    background: white;
    margin: 0 calc(var(--basePadding) * -1);
    width: 100%;
    padding: 24px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    bottom: 0;
  }

  .sheet-summary p {
    color: var(--primaryColor);
    padding: 0;
    font-weight: 400;
    line-height: 1.1 !important;
  }

  .summary-line {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
  }

  .summary-buttons {
    display: flex;
    justify-content: space-between;
  }

  .summary-buttons .btn {
    flex: 0 0 49%;
  }

  .points-earned {
    margin-bottom: 24px !important;
    margin-top: 5px !important;
  }

  html.inner-page {
    overflow: hidden;
  }

  html.inner-page footer.et-l.et-l--footer {
    display: none;
  }

  .points-required {
    position: relative;
  }

  .points-required .points-nb {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  .iconBack {
    width: 16px !important;
    height: 16px !important;
    position: absolute;
    top: 18px !important;
    left: 0px !important;
  }

  /* num pad */
  .num-pad {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    height: 100%;
  }

  .num-pad > button {
    flex: 0 0 30%;
    max-height: 80px;
    font-size: 3.5vh;
    padding: 0;
    margin: 10px 0;
  }

  /* .num-pad>button:last-child {
      margin: 10px auto;
    } */

  /* Transaction Amount Num Pad */
  .transaction-num-pad {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    height: 100%;
  }

  .transaction-num-pad > button {
    flex: 0 0 30%;
    font-size: 3.5vh;
    padding: 0 !important;
    margin: 0px 0px 16px 0px;
    width: 97px;
    height: 60px;
  }

  .transaction-num-pad > button:last-child {
    font-size: 14px;
    border: solid 2px var(--partnerBorderColor1);
    font-weight: 400;
    background: unset;
  }

  .input-group .icon {
    width: 1.3rem;
    height: 1.3rem;
  }

  .magasin-init .form-group label {
    font-size: 12px;
  }

  .magasin-init {
    background: var(--secondaryColor);
  }

  .magasin-init .page-header {
    height: 20px;
  }

  .magasin-init .page-content .page-header .icon {
    right: -10px;
    width: 50px;
    height: 50px;
  }

  .shop-wrap {
    display: flex;
    align-items: center;
    width: 80%;
    margin: auto;

    svg {
      width: 20%;
    }

    .hex {
      stroke-width: 4;
      stroke: white;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }

  .shop-wrap .shop-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .shop-wrap p {
    margin-left: 30px !important;
    color: white;
  }

  .marginTop-3 {
    margin-top: 3px;
  }

  p {
    padding-bottom: unset !important;
  }

  .magasin-init h2 {
    color: white !important;
    margin-top: 30px !important;
    margin-bottom: 20px !important;
    line-height: 1.2 !important;
  }

  .magasin-init .page-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .magasin-init .page-content > .content-wrap {
    flex: 1;
  }

  #offersWrap .card {
    padding: 8px 20px !important;
  }

  .offer-btn {
    font-size: 12px !important;
    padding: 10px 20px !important;
  }

  .amountSpan {
    background-color: white;
    text-align: center;
    width: 30%;
    border-radius: 50px;
  }

  .sponsor-div {
    display: grid;
    grid-template-columns: 18px auto;
    align-items: center;
    margin-top: 5px;
  }

  .sponsor-text-custom {
    font-size: 11px;
    text-transform: uppercase;
    color: #0091ff;
  }

  .cc-revoke {
    display: none !important;
  }

  .checkmark {
    background-color: white !important;
  }

  /* Magasin Transaction Amount Screen */
  #magasinTransactionAmount,
  #magasinValidate {
    background: var(--secondaryColor) !important;
  }

  .magasin-back-icon-div {
    margin: 20px 0px 10px 0px !important;
  }

  .txtLeft {
    text-align: left !important;
  }

  .back-arrow-icon {
    position: unset !important;
  }

  .magasin-transaction-user-card {
    padding: 0 0 0 10px !important;
  }

  .amountLabelTxt {
    color: white;
    line-height: 1.27 !important;
    font-size: 22px;
    font-weight: bold;
    height: 57px;
    margin-bottom: 34px !important;
  }

  .amountCurrencyDiv {
    left: 80px;
    position: relative;
    top: 40px;
    display: inline;
  }

  .magasin-transaction-amount-box {
    width: 70%;
  }

  .magasin-transaction-amount-span {
    border-radius: 21px;
    display: flex;
    align-items: center;
    width: unset;
    margin-bottom: 40px;
  }

  .magasin-transaction-input {
    max-width: 90%;
    height: 55px;
    width: unset !important;
    padding: 0px 0px 0px 34px !important;
    box-shadow: none !important;
  }

  .transaction-numpad-wrapper {
    flex: 1;
    padding: 0px 26px;
  }

  /* Feedback loading bar */
  .round-time-bar {
    margin: 1rem;
    overflow: hidden;
    background: white;
    border-radius: 10px;
  }

  @keyframes roundtime {
    to {
      /* More performant than `width` */
      transform: scaleX(0);
    }
  }

  .round-time-bar div {
    position: relative;
    height: 12px;
    border-radius: 10px;
    animation: roundtime;
    animation-duration: 10s;
    transform-origin: right center;
    background: lightgray;
    animation-iteration-count: initial;
  }

  .btn-flat:active {
    -webkit-transform: scale3d(0.9, 0.9, 1);
    transform: scale3d(0.9, 0.9, 1);
  }

  .magasin-validate-back {
    margin-bottom: 45px !important;
  }

  .magasin-transaction-btn {
    background: var(--secondaryColor) !important;
    border: solid 2px #e59da2;
  }

  .magasin-transaction-amount-wrap {
    flex-flow: row !important;
    align-items: center;
    background: var(--secondaryColor) !important;
    margin-left: auto;
    margin-right: auto;
  }

  .m-35 {
    margin-left: 35% !important;
  }

  .scanner-cust {
    margin-right: 10px !important;

    .magasin-transaction-btn {
      display: flex;
      align-items: center;
      font-style: normal;
      padding: 12px;
      border-radius: 21px;
      background-color: #9bca3b !important;
      border: 1px solid #9bca3b !important;

      span {
        padding-left: 10px;
        text-align: left;
      }
    }
  }

  .i-infoWrapper {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    align-items: center;
    grid-gap: 8px;
    padding: 0 15px;

    p {
      font-family: Roboto;
      font-size: 11px;
      color: #FFFFFF;
    }
  }
}
