@import 'constant.scss';

.bvnV2 {
  .homePage_Container {
    font-family: $fontFamily;
  }

  .homePage_header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px;
  }

  .homePage_header img {
    height: 120px;
    display: block;
    object-fit: contain;
  }

  .homePage_header div {
    display: flex;
    justify-content: center;
  }

  .homePageBody {
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
  }

  .homePage_backGroundImage1 {
    background-image: url('./assets/26155924/Desktop1.jpg');
    background-position: center;
    background-size: cover;
    padding: 50px;
  }

  .homePage_MainTitle {
    color: $white;
  }

  .homePage_Titles {
    color: $white;
  }

  .startDate {
    color: $white;
    padding: 0px 180px;
  }

  .homePage_whiteButton {
    color: $TxtColor1;
    border-radius: $borderRadius;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    background-color: $white;
    padding: 10px 20px !important;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    margin: 30px 0 !important;
  }

  .homePage_whiteButton > a {
    color: $TxtColor1;
  }

  .homePage_LogOut {
    color: white;
    font-weight: bold;
    border-bottom: 2px solid white;
    cursor: pointer;
  }

  .process_title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px !important;
    color: $TxtColor1;
    font-size: 36px;
    text-align: center;
  }


  .helpDesk {
    color: white;
    text-align: center;
    margin-top: 40px;
  }

  .helpDesk > p:last-of-type {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 1000;
  }

  .homePage_footer {
    display: flex;
    justify-content: space-evenly;
    padding-top: 30px;
    flex-wrap: wrap;
  }


  .homePage_footer img {
    height: 160px;
    height: 78px;
    margin-bottom: 40px;
  }

  .process_Wrapper {
    padding: 10px 0 70px;
    background-image: url('./assets/26161535/mobile2landingcommerces-scaled.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  /* Medium devices (landscape tablets, 768px and down) */
  @media only screen and (max-width: 768px) {
    .homePage_backGroundImage1 {
      padding: 0px;
    }
    .homePageBody {
      padding-top: 30px;
      width: 90%;
    }

    .homePageBody p {
      font-size: 20px;
    }
    .homePage_MainTitle {
      font-size: 26px;
    }
    .homePage_header {
      display: flex;
      margin: 20px 10px;
      column-gap: 10px;
    }

    .homePage_header img {
      height: 60px !important;
    }

    .homePage_Titles {
      font-size: 24px;
    }
    .startDate {
      font-size: 24px;
    }

    p.homePage_whiteButton {
      color: $TxtColor1;
    }
    .homePage_whiteButton > p {

      color: $TxtColor1;
    }

    .sellerCode {
      font-size: 29px;
    }

    .homePage_LogOut {
      font-size: 28px;
    }

    .process_title {
      padding: 5px;
    }
    .process_title > h1 {
      font-size: 20px;
    }

    .helpDesk {
      font-size: 20px;
    }
    .homePage_footer {
      align-items: center;
    }

    .homePage_footer img {
      height: 70px;
    }
  }

  /* Medium and Large devices (laptops/desktops, 768px and up) */
  @media only screen and (min-width: 768px) {
    .homePageBody {
      width: 84%;
    }

    .homePage_header {
      justify-content: space-between;
      flex-direction: row;
    }

    .homePage_header > div {
      width: 50%;
    }
    .homePage_header img {
      height: 60px !important;
    }

    .homePage_MainTitle {
      font-size: 40px;
    }
    .homePage_Titles {
      font-size: 34px;
    }
    .startDate {
      font-size: 34px;
    }

    .homePage_whiteButton {
      font-size: 20px;
    }

    .sellerCode {
      font-size: 26px;
    }

    .homePage_LogOut {
      font-size: 28px;
    }
    .process_title > h1 {
      font-size: 36px;
    }
    .helpDesk {
      font-size: 20px;
    }

    .homePage_footer img {
      height: 120px;
    }
  }

  /* Extra large devices (large laptops and desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .homePageBody {
      width: 84%;
    }

    .homePage_header {
      justify-content: space-between;
    }

    .homePage_header img {
      height: 90px !important;
    }

    .homePage_MainTitle {
      font-size: 45px;
    }
    .homePage_Titles {
      font-size: 30px;
      width: 60vw;
    }
    .startDate {
      font-size: 20px;
    }

    .homePage_whiteButton {
      font-size: 20px;
    }


    .sellerCode {
      font-size: 25px;
    }
    .sellerCode:hover {
      position: relative;
      bottom: 0px;
      left: 0px;
      box-shadow: none;
    }

    .homePage_LogOut {
      font-size: 28px;
    }
    .homePage_LogOut:hover {
      position: relative;
      bottom: 3px;
      left: 3px;
    }

    .process_Wrapper {
      background-image: url('./assets/26161535/commentcamarche-desktop.jpg');
      background-position: 30% 0%;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 50px;
    }
    .process_title > h1 {
      font-size: 36px;
    }
    .helpDesk {
      font-size: 20px;
    }

    .homePage_footer {
      align-items: center;
    }
  }

  /* Extra large devices (large laptops and desktops, 1024px and up) */
  @media only screen and (min-width: 1024px) {
    .homePageBody {
      width: 60%;
    }

    .homePage_header {
      justify-content: space-around;
    }
    .homePage_footer {
      align-items: center;
    }
  }

  @media only screen and (max-width: 360px) {
    .menu div.burgerMenu {
      width: 100%;
    }
    .menu .burgerMenu div.navBlock {
      width: 90%
    }
  }
}