@import './constant.scss';
@import './common.scss';

.Etablissements {
  box-sizing: border-box;

  .searchBar {
    background: $BtnColor1;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    border: none;
    border-radius: 0;
    margin: 0 0 20px;
    padding: 0;

    input {
      background: white;
      height: 30px;
      flex: 1;
      min-width: 200px;
      width: auto;
      text-align: left;
      padding: 0 15px 0 10px;
      border: solid 2px $BtnColor1;
      border-radius: 30px 0 0 30px;
    }
    .searchText {
      color: $BtnTxtColor1;
      font-size: 18px;
      font-weight: bold;
      background: $BtnColor1;
      height: 100%;
      border-radius: 0 30px 30px 0;
      margin: 0;
      padding: 5px 30px;
      cursor: pointer;
    }
    .searchIcon {
      display: none;
      background: $BtnColor1;
      height: 30px;
      width: 30px;
      border-radius: 30px;
      margin: 0 0 0 -15px;
      padding: 7px;
      cursor: pointer;

      img {
        height: 100%;
      }
    }
  }

  .mapBlock {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 40px 10%;

    .categoryFilters {
      flex: 2;
      margin-right: 20px;
      align-self: flex-start;
      background: white;
      border-radius: 8px;
      box-shadow: 0 0 10px #CFCFCF;
      padding: 30px;
      max-width: 280px;

      .header{
        padding:  20px 20px;
        text-align: center;
        color: $BtnColor1;
        border-bottom: solid 2px #F8F8F8;
        font-size: 16px;
      }
    }
    .categButton {
      display: flex;
      justify-content: space-between;
      margin: 14px 10px;
      align-items: center;
      cursor: pointer;

      .pin {
        width: 30px;
      }

      .label {
        flex: 1;
        margin: 0 10px;
        text-align: center;
        color: #565656;
        font-weight: 600;
        font-size: 15px;
      }
    }
    .map {
      position: relative;
      flex: 1;

      .PinPopup {
        background: white;
        border-radius: 8px;
        box-shadow: 0 0 10px #CFCFCF;
        align-self: flex-start;
        padding: 20px 0;
        width: 200px;
        position: absolute;
        bottom: 10px;
        right: -110px;

        .info {
          padding: 10px;
          padding-bottom: 0;
          font-size: 17px;
          color: $BtnTxtColor1;
          font-weight: bold;
          text-align: center;
          border-top: solid 5px white;
        }
      }

      .moreSellers {
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
        background: var(--partnerBtnColor1);
        color: var(--partnerBtnTxtColor1);
        padding: 4px 20px;
        border-radius: 30px;
      }
    }
  }

  .tabulator {
    margin: 10px 10%;
    width: 80%;

    .tabulator-headers {
      color: $BtnColor1;
    }
    input {
      border: solid 1px rgb(118, 118, 118);
    }
  }

  .map {
    .PinPopup {
      .info {
        color: $ClientMainBtnColorText;
      }
    }
  }

  /* Extra small devices (phones, 992px and down) */
  @media only screen and (max-width: 992px) {
    .navbar_bigScreen {
      display: none;
    }

    .contentWrapper {
      display: flex;
      flex-direction: column;
    }
  }

  /* Large devices (laptops/desktops, 600px and down) */
  @media only screen and (max-width: 600px) {
    .categoryFilters {
      display: none;
    }
    .searchBar {
      input {
        min-width: 0;
      }

      .searchText {
        display: none;
      }
      .searchIcon {
        display: initial;
      }
    }

    &.ticketpei {
      .searchBar {
        input {
          min-width: 0;
          border-radius: 30px;
        }
      }
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
  }

  @media only screen and (max-width: 1024px) {
  }
}