@import './constant.scss';
@import './common.scss';

.headerWrapper {
  display: flex;
  padding-left: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
  height: 72px;

  .headerLogo {
    display: flex;
    width: 40%;
    flex-wrap: nowrap;
    flex-direction: row;
    img {
      display: block;
      object-fit: contain;
    }

    img:nth-child(1) {
      width: 70%;
      margin-right: 5px;
    }
    img:nth-child(2) {
      width: 30%;
      margin-left: 5px;
    }
  }

  .headerHelper {
    flex-grow: 1;
    display: flex;
    div {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      font-size: 2em;
      font-weight: 900;
      color: $TxtColor1;
      a {
        color: $TxtColor1;
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 768px) {
  .headerWrapper {
    display: none;
  }
}
