@import './constant.scss';


  .textDiv {
    margin: 30px 0 0;
    padding: 20px 16px 21px 14px;
    border-radius: $borderRadius;
    box-shadow: $boxShadow2;
    background-color: $white;
    font-size: 18px;
    color: $TxtColor2;
    text-align: center;
  }
