
.bvnV2 {
  #main-header {
    display: none;
  }

  #page-container {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  body > img {
    display: none;
  }

  #clientSearch {
    font-weight: normal;
    font-size: 14px;
    color: #4e4e4e;
  }

  #clientSearch::placeholder {
    font-weight: normal;
    font-size: 14px;
  }

  #clientSearch {
    padding-left: 80px !important;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: 14px;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    font-size: 14px;
  }

  .search-wrap {
    display: flex;
    margin-bottom: 24px;
    //   position: sticky;
    //   top: -1px;
  }

  .search-wrap.isSticky {
    margin: 0 -16px;
    padding: 16px;
    background: white;
  }

  .search-wrap .input-group {
    flex: 1;
  }

  .search-wrap .input-group .icon {
    background-size: contain;
    width: 24px;
    height: 24px;
    margin-top: -4px;
  }

  .search-wrap > .btn {
    border-radius: 100%;
  }

  .search-wrap > .btn .icon {
    height: 16px;
    width: 16px;
  }

  .avatar-wrap img {
    z-index: 0 !important;
  }
}
