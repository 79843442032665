@import "constant";

.bvnV2 {
  .landingheader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 10px;

    .logos {
      display: flex;
      flex-direction: row;
      max-width: calc(100% - 110px);
      z-index: 1;

      .ticoLogo {
        width: 400px;
        max-width: 55%;
        object-fit: contain;
      }

      .LogoBlock {
        margin: 0;

        a {
          height: 80px;
        }
      }

      .logo.negative {
        display: none;
      }
    }

    .links {
      margin-left: auto;

      .navbar_bigScreen {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      .navbar_bigScreen > a {
        color: $ClientColor1;
        font-weight: bold;
        font-size: 20px;
        margin: 0 10px;
        text-transform: uppercase;
      }

      .hbg-menu {
        display: none;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 25px;
      }
    }
  }

  .navbar_smallScreen {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;

    a {
      color: $ClientColor1;
    }
  }

  @media screen and (max-width: 600px) {
    .landingheader {
      .links {
        .navbar_bigScreen {
          display: none;
        }

        .hbg-menu {
          display: flex;
        }
      }
    }

    .navbar_smallScreen {
      display: flex;
    }
  }

  .myprovence {
    .navbar_bigScreen > a {
      color: var(--textColor);
    }

    .landingheader {
      font-family: MostraOne;
    }
  }

  .ticketpei.landingTicket {
    .landingheader {
      height: 0;
      padding: 0;

      .logos {
        margin-left: 0;

        .color {
          display: none;
        }

        .negative {
          display: block;
          position: absolute;
          height: 92px;
          top: 5px;
          left: 150px;

          a {
            height: auto;
          }
        }
      }
    }
  }
}