@import "constant";

.menu {
  .burgerIcon {
    
    margin: auto;
    background: none;
    left: 20px;
    border: solid 2px $TxtColor1;
    border-radius: 7px;
    height: 50px;
    width: 50px;

    svg {
      margin: 10px;
    }

    path {
      fill: $TxtColor1;
    }
  }

  .sellerSelect {
    border: none;
    border-radius: 20px;
    height: 55px;
    margin-top: 10px;
    outline: none;
    padding-left: 10px;
  }

  .burgerMenu {
    position: fixed;
    height: 100%;
    width: 375px;
    background: $BtnColor1;
    top: 0;
    left: 0;
    transition: transform 500ms;
    transform: translateX(-100%);
    color: $BtnTxtColor1;
    z-index: 2;
    padding: 16px;
    display: initial;
    justify-content: initial;

    div {
      justify-content: initial;
    }

    background-image: linear-gradient(to bottom, $TxtColor1, #1d2445);

    .close {
      border: none;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 20px;
      background: none;

      svg {
        height: 40px;
        width: 40px;
      }

      path {
        stroke: $BtnTxtColor1;
      }
    }

    .disconnect {
      background: none;
      border: none;
      color: $BtnTxtColor1;
      cursor: pointer;
    }

    .infoBlock {
      margin-top: 20px;
      font-size: 20px;
      display: flex;

      .sideMenuImg {
        width: 79px;
        height: 88.4px;
        object-fit: contain;
      }

      svg {
        width:20%;
      }

      #hex {
        stroke-width:4;
        stroke: white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }

      .sideMenuHeading {
        width: 193px;
        height: 25px;
        margin: 23px 4px 8px 16px;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.09;
      }
    }

    .navBlock {
      position: absolute;
      bottom: 0;
      width: 343px;
      height: auto;
      margin: 19px 0 0;
      padding: 37px 48px 28px 38px;
      border-radius: 50px 50px 0px 0px;
      background-color: $TxtColor1;
      display: initial;
      justify-content: initial;

      .navLink {
        font-size: 20px;
        margin-bottom: 31px;
        cursor: pointer;
      }

      .navLink > a {
        font-weight: bold;
        color: white !important;
      }
      

      .sideMenuSubLink {
        font-size: 14px;
        font-weight: normal !important;
      }
    }
    .sideMenuFooter {
      padding-top: 13px;
      display: flex;
      justify-content: space-between;
    }
  }
  @media (max-width: 768px) {
    .burgerIcon {
      height: 40px;
      width: 40px;
    }
  }
}
