@import './constant.scss';

.popupOverlay {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 999999;

    .cancelBtn{
        position: absolute;
        top: 3px;
        right: 3px;
        border-radius: 20px;
        height: 20px;
        width: 20px;
        font-size: 17px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $ClientColor3;
        background: none;
        border: solid 2px $ClientColor3;
    }

    .popupBase{
        border: solid 1px $ClientColor3;
        position: relative;
        background: $white;
        z-index: 999999;
        padding: 19px 8px 23px 10px;
        border-radius: 3px;
        -webkit-box-shadow: $boxShadow3;
        box-shadow: $boxShadow3;
        width: 100%;
        max-width: 90%;
        margin: auto;
    }

    .ticketInfoBaseWrapper{
        color: $ClientColor3;
        text-align: center;
        font-size: 20px;
        line-height: 1.1;
        div{
            font-family: 'Draft-regular';
            margin-bottom: 20px;
        }
        .popuptitle{
            text-transform: uppercase;
            font-weight: bold;
        }
    }


    .buttonWrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        button {
            margin: 5px 10px;
            width: 150px;
            min-height: 40px;
            font-size: 12px;
            border-radius: 50px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .refundBlock {
        border-top: solid 1px $ClientColor3;
        margin: 10px -8px -23px -10px;
        padding: 12px 5px;
        text-align: center;
        background: $ClientBackgroundColor;

        span {
            text-decoration: underline;
        }

        a {
            font-size: 17px;
            line-height: 20px;
            color: $ClientColor1;
        }
    }

    @media only screen and (max-width: 350px){
        .popupBase{
            bottom: 10px;
        }
    }

    @media only screen and (min-width: 541px){
        .popupBase{
            max-width: 400px;
        }
    }
}