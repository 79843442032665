@import "constant.scss";

.LoaderWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

.bg-blue{
  background: $blue;
}

.bg-red{
  background: var(--secondaryColor);
}

.bg-magasin {
  background: var(--magasinLoadingBackground);
}

.LoaderWrapper > p {
  color: $white;
}
