.bvnV2 {
  .adsCardContainer {
    position: relative;
  }

  .images {
    border: 2px solid #c0c0c0;
  }

  .adsCoverImage {
    max-width: 40px
  }

  .adsImage {
    min-height: 1px;
    height: auto;
    max-width: 100%;
    vertical-align: top;
  }

  // .woocommerce img,
  // .woocommerce-page img {
  //   height: auto;
  //   max-width: 100%;
  // }
  .floatingBlock {
    position: absolute;
    top: 0;
    right: 20px;
    width: 170px;
    box-shadow: 0 3px 8px rgb(0 0 0 / 80%);
    //   height: 300px;
    height: 99%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .floatingBlock .head {
    position: relative;
    display: block;
    background: var(--partnerTxtColor1);
    height: 30px;
    margin-bottom: -1px;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2844' height='200.001' viewBox='0 0 2844 200.001'%3E%3Cpath id='Exclusion_2' data-name='Exclusion 2' d='M2844,200H0V0H2844V200ZM1422,11a5,5,0,1,0,5,5A5.006,5.006,0,0,0,1422,11Z' transform='translate(0 0)'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    mask-position: top center;
    flex: 0 1 12%;
  }

  .floatingBlock .head:after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    background: var(--partnerTxtColor8);
    top: 0;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    left: 50%;
    transform: translateX(-50%);
  }

  .floatingBlock .content {
    background: var(--partnerTxtColor1);
    padding: 10px;
    flex: 1 67%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .adsImage {
    height: 350px;
    width: 100%;
    object-fit: cover;
  }

  .floatingBlock .content .category {
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.2;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .floatingBlock .content .title {
    background: #fff;
    padding: 10px;
    text-align: center;
    color: var(--partnerTxtColor1);
    font-weight: bold;
    margin-top: 10px;
    text-transform: uppercase;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .floatingBlock .content .subtitle {
    font-size: 1.2em;
  }

  .floatingBlock .content .subtext {
    font-size: 1em;
  }

  .floatingBlock .icon {
    background-image: url("data:image/svg+xml,%3Csvg height='300px' width='300px' fill='%23fff' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 54 54' version='1.1' x='0px' y='0px'%3E%3Ctitle%3E1_right arrow%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M27,53.8153846 C12.2307692,53.8153846 0.184615385,41.7692308 0.184615385,27 C0.184615385,12.2307692 12.2307692,0.184615385 27,0.184615385 C41.7692308,0.184615385 53.8153846,12.1846154 53.8153846,27 C53.8153846,41.8153846 41.7692308,53.8153846 27,53.8153846 Z M27,3.96923077 C14.3076923,3.96923077 3.96923077,14.3076923 3.96923077,27 C3.96923077,39.6923077 14.3076923,50.0307692 27,50.0307692 C39.6923077,50.0307692 50.0307692,39.6923077 50.0307692,27 C50.0307692,14.3076923 39.6923077,3.96923077 27,3.96923077 Z M20.8153846,40.3846154 C20.0769231,39.6461538 20.0769231,38.4461538 20.8153846,37.7076923 L31.6615385,26.8615385 L20.8153846,16.0153846 C20.0769231,15.2769231 20.0769231,14.0769231 20.8153846,13.3384615 C21.5538462,12.6 22.7538462,12.6 23.4923077,13.3384615 L35.6769231,25.5230769 C36.0461538,25.8923077 36.2307692,26.3538462 36.2307692,26.8615385 C36.2307692,27.3692308 36.0461538,27.8307692 35.6769231,28.2 L23.4923077,40.3846154 C23.1230769,40.7538462 22.6153846,40.9384615 22.1538462,40.9384615 C21.6461538,40.9384615 21.1846154,40.7538462 20.8153846,40.3846154 Z' fill='%23fff' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    float: right;
  }

  .floatingBlock .sellerBtn {
    display: block;
  }

  .floatingBlock a {
    color: white;
    background: var(--partnerTxtColor8);
    text-transform: uppercase;
    padding: 10px 20px;
    display: block;
    font-weight: bold;
  }

  .adsInfoBox {
    border-left: 2px solid #c0c0c0;
    border-right: 2px solid #c0c0c0;
    border-bottom: 2px solid #c0c0c0;
    padding: 3% 0% 3% 5%;
    font-size: 14px;
    line-height: 2.5rem;
    font-weight: bold;
  }

  .adsInfoHeading {
    color: var(--partnerTxtColor1);
    text-transform: uppercase;
  }

  .adsInfoContent {
    color: var(--partnerTxtColor9);
  }

  @media (max-width: 750px) {
    .section-carousel {
      border: none !important;
    }
  }
  @media (min-width: 750px) {
    .floatingBlock {
      // height: 80%;
      width: 40%;
    }
  }
}