@import 'constant.scss';

.bvnV2, .adminBvn {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .hide {
    display: none;
  }

  input {
    border: none;
  }

  .error {
    margin: 0;
  }

  .register_error {
    color: $red;
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
  }

.register_sellerAccountAlreadyExist {
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  color: var(--partnerTxtColor2);
  font-weight: bold;

  & a {
    color: var(--partnerTxtColor3);
  }
}

  .register_message {
    color: $TxtColor2;
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
  }

  .RegisterPage_container {
    display: flex;
    flex-wrap: wrap;
    background-color: $BgColor;
    padding-bottom: 50px;
    max-width: 1500px;
    margin: auto;

    .formTicketCo_Title {
      margin: 30px;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .cardText {
        position: absolute;
        margin-left: 20px;
        right: 10px;
        top: 400px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 450px;
        height: 500px;
      }
    }

    .formTicketCo_Title p {
      color: $TxtColor3;
      font-size: 2.5em;
      text-align: center;
      line-height: 50px !important;
      font-family: draft, roboto, arial
    }

    .text-decoration {
      text-decoration: underline;
    }

    .cardText > p {
      text-align: justify;
    }

    .formTicketCo_Title p:nth-child(1) {
      font-weight: 1000;
    }
  }

  .Content_wrapper {
    width: 400px;
    margin: 0 auto;
    background-color: transparent;
  }

  .signUpPage_wrapper {
    padding-bottom: 40px;
  }

  .signUpPage_Header {
    text-align: center;
    color: $TxtColor3;
    margin-top: 40px;
    font-size: 27px;
    font-weight: bold;
    font-family: $titleFontFamily;
    line-height: 1em;
  }

.signUpPage_SubHeader {
  text-align: center;
  color: var(--partnerTxtColor2);
  margin-top: 40px;
  font-size: 25px;
  font-family: $titleFontFamily;
  line-height: 1em;

  & h5 {
    font-weight: normal !important;
  }

  & a {
    color: var(--partnerTxtColor3);
  }
}

  .formWrapper {
    & label {
      color: $TxtColor2;
      font-weight: bold;
    }

    & label[for=ownerPhone] {
      margin-bottom: 10px;
    }

    & p {
      display: flex;
      flex-direction: column;
      color: $blue;
      margin-top: 10px !important;
      max-width: 400px;

      & input {
        border-radius: 20px;
        width: 100%;
        height: 55px;
        margin-top: 10px;
        outline: none;
        padding-left: 20px;
      }

      & input[type='radio'] {
        height: initial;
        width: initial;
        margin: 0 10px 0 20px;
      }

      & textarea {
        border-radius: 20px;
        width: 100%;
        margin-top: 10px;
        outline: none;
        padding-left: 20px;
        border: none;
      }
    }

    & .formSubTitle {
      margin: 30px 59px 13px 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.71;
      color: $TxtColor3;
      margin-bottom: 10px;
    }

    & label[for=category] {
      margin-bottom: 10px;
    }

    & .required {
      color: $red;
    }

    & .subLabel {
      font-weight: normal;
      font-style: italic;
    }

    & select {
      color: $blue;
    }
  }

  .signUpcheckboxes {
    display: flex;
    margin-top: 20px;

    & a {
      color: $TxtColor3;
      font-weight: bold;
    }
  }

  .signUpcheckboxes label {
    flex: 1;
    margin-left: 10px;
  }

  .signUpcheckboxes p:nth-child(1) {
    margin-top: 2px;
  }

  .signUpcheckboxes p:nth-child(2) {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .signUpPage_wrapper button {
    margin-top: 20px;
  }

  .cityList {
    border: none;
    border-radius: 20px;
    height: 55px;
    margin-top: 10px;
    outline: none;
    padding-left: 10px;
  }

  .categoryList {
    border: none;
    border-radius: 20px;
    height: 55px;
    margin-top: 10px;
    outline: none;
    padding-left: 10px;
  }

  select:focus, textarea:focus, input:focus {
    outline: 2px solid $BtnColor2 !important;
  }

  .sigIn_passwordReinit a {
    display: block;
    color: $TxtColor2;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .mapContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: $transparentBlue;
    position: fixed;
    top: 0;
    left: 0;
  }

  .mapWrapper {
    display: flex;
  position:absolute;
  bottom : 90px;
    flex-direction: column;
    width: 300px;
    max-height: 100%;
    overflow: scroll;
  }

  .mapQuestion {
    color: $white;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
  }

  .mapInputs {
    display: flex;
    flex-direction: column;
  }

  .mapInputs > input {
    display: inline-block;
    width: 300px;
    height: 55px;
    border-radius: 20px;
    border: none;
    outline: none;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .mapButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .mapButtons > button {
    width: 100px;
  }

  .mapMarker {
    width: 32px;
    height: 32px;
    transform: translate(-50%, -50%);
  }

  .hide {
    display: none;
  }

  .displayInput {
    display: display;
  }

  .hideInput {
    display: none;
    border: 2px solid orange;
  }

  .register_edito {
    margin-left: 150px;
  }

  .register_flex {
    flex: 1;
    margin: 0 auto;

    & .LogoBlock.big {
      margin-left: 150px;
    }
  }

  /* Extra small devices (phones, 768px and down) */
  @media only screen and (max-width: 768px) {
    .Content_wrapper {
      width: 70%;
    }

    .register_edito {
      display: none;
    }

    .register_flex {
      flex: initial;

      & .LogoBlock.big {
        margin-left: auto;
      }
    }
    .formTicketCo_Title p {
      font-size: 1.5em !important;
    }
    .mapInputs input {
      margin-bottom: 5px;
      margin-top: 5px;
      height: 35px;
    }
  }

  /* Extra large devices (large laptops and desktops, 1300px and down) */
  @media only screen and (max-width: 1350px) {
    .cardText {
      display: none !important;
      // background-color: red !important;
    }
  }
}
