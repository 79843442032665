@import 'constant.scss';

.error-popup {
    position: relative;
    background: $white;
    z-index: 999999;
    line-height: 100px;
    border-radius: 16px 16px 0 0;
    -webkit-box-shadow: var(--boxShadow3);
    box-shadow: var(--boxShadow3);
    top: 50%;
    left: 50%;
    width: 40%;
    min-width: 300px;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    align-items: center;

    button {
        width: 150px;
        border: 1px solid #F4E75D;
        box-sizing: border-box;
        border-radius: 50px;
        background: transparent;
        padding: 10px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #F4E75D;
        margin-top: 24px;

  }
}