.bvnV2 {
  .screen2Heading {
    color: var(--partnerTxtColor5);
    margin-top: 25px;
    margin-bottom: 7px;
    margin-left: 11px;
  }

  .screen2Subheading {
    color: var(--partnerTxtColor3);
    margin-bottom: 21px !important;
    padding: 0px !important;
    margin-left: 11px;
  }

  .screen2Label {
    font-size: 18px;
    color: var(--partnerTxtColor3);
    font-weight: normal;
    font-stretch: normal;
    margin-bottom: 16.5px !important;
  }

  .tableSelect {
    border: 1px solid var(--partnerTxtColor3);
  }

  .dataLabel {
    width: 250px;
  }

  .dateBox {
    width: 50%;
  }

  // input[type="date"]::-webkit-calendar-picker-indicator {
  //     visibility: hidden;
  // }
  .imageBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .imgInputLabel {
    width: 80%;
  }

  .imgName {
    font-size: 12px;
    color: var(--partnerTxtColor3);
  }

  .imgInputBox {
    width: 130px !important;
    height: 130px !important;
    border-radius: 11px;
    background-color: #ffffff;
    position: relative;
  }

  .cameraIcon {
    //   position: absolute;
    //   left: 30%;
    //   top: 33px;
    height: 49px;
    width: 50px;
    margin: auto;
    position: relative;
    top: -72%;
  }

  .addIcon {
    width: 36px;
    height: 36px;
    background-color: #56c596;
    border-radius: 50%;
    position: absolute;
    // top: 0;
    top: 100px;
    left: -10px;
    padding-left: 10px;
    padding-top: 10px;
  }

  .imgInput {
    height: 100%;
    width: 100%;
    opacity: 0;
    position: relative;
    z-index: 3;
    cursor: pointer;
  }

  .imgInput::file-selector-button {
    width: 10px;
  }

  .screen2InfoBox {
    width: 100%;
    height: 144px !important;
    line-height: normal;
  }
}