@import "constant.scss";
@import "common.scss";

.bvnV2 {
  margin: 0 auto !important;
  font-family: $fontFamily !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-grey !important;
  font-size: $baseFontSize;
  color: $textColor;
  overflow-x: hidden;
  min-width: 320px;
  line-height: 1.4285em;
}
