@import './constant.scss';
// .hexagon-clipp {
//     background: #3a4581;
//     height: 90px;
//     width: 90px;
//     display: none;
//   }

//   .hexagon-clipp > div:first-of-type {
//     display: flex;
//     justify-content: center;
//     flex-direction: column;
//     height: 100%;
//   }

//   .hexagon-clipp h3 {
//     color: white;
//     font-size: 20px;
//   }

//   .hexagon-clipp span {
//     font-size: 10px;
//     font-weight: bold;
//     line-height: 0.9;
//     color: #ffffff;
//   }

.bvnV2 {
  .dataDiv {
    width: 49%;
    height: 86px;
    padding: 27px 0 0 20px;
    border-radius: 21px;
    box-shadow: $boxShadow4;
    background-color: #fff;
    text-align: left;
    color: $TxtColor1;
  }

  .kpiDiv {
    width: calc(50% - 10px);
    height: 86px;
    padding: 27px 0 0 20px;
    border-radius: 21px;
    box-shadow: $boxShadow4;
    background-color: #fff;
    text-align: left;
    color: $TxtColor1;
  }

  .couponDiv {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 15px;
    border-radius: 21px;
    background: $white;
    height: 55px;
    align-items: center;
  }

  .couponDiv p {
    color: $TxtColor1;
    font-weight: 600;
  }

  .kitCardsWrapper {
    display: flex;
    justify-content: space-between;
    height: 50px;
  }

  .couponImagesWrapper img {
    padding-right: 5px;
  }

  .carouselDiv {
    height: 210px;
    border-radius: 21px;
    background: #00898f;
    margin-top: 16px;
  }

  .footerWrapper {
    margin-top: 40px;
    color: $primaryColor;
    margin-bottom: 32px;
  }

  #salesDiv span {
    font-weight: $fontWeightBold;
  }

  .monSoldeBtn {
    margin-top: 15px;
    color: $white !important;
    border-radius: $borderRadius;
    background-color: $BtnColor1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    padding: 15px;
  }

  a.footerNum {
    color: $primaryColor !important;
  }

  .kitDiv {
    margin: 30px 0 0;
    padding: 20px 16px 21px 14px;
    border-radius: $borderRadius;
    box-shadow: $boxShadow2;
    background-color: $white;
    font-size: 15px;
  }

  .kitDiv > p {
    text-align: left;
    color: $primaryColor;
    margin: 0 6px 16px !important;
  }

  .kitBtn {
    flex: 1;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $TxtColor1 !important;
  }
}