@import 'constant.scss';
@import 'common.scss';

.client-layout {
  position: relative;
  background: $ClientBackgroundColor;
  min-height: 100%;

  .client-header {
    background: #F6F8FA;
    height: 75px;
    display: flex;
    align-items: center;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 1;

    .menuIcon {
      fill: $ClientColor3;
    }

    button {
      background: none;
      border: none;
      margin-left: 24px;
    }

    .logo-wrapper {
      width: 130px;
      height: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      padding-top: 10px;
      padding-bottom: 10px;

      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .svgIcon{
    color: $ClientColor3;
    width: 32px;
    height: 32px;
    min-width: 32px;
  }

  .client-menu {
    background: #F6F8FA;
    z-index: 1;
    border-right: 2px solid $ClientColor1;
    top: 75px;
    bottom:0;
    width: 305px;
    position: fixed;
    padding-left: 24px;
    transition: transform 500ms;
    transform: translateX(-100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ul {
      list-style: none;
      // margin-top: 32px;
      li {
        margin-top: 22px;
        a{
          font-family: Roboto;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color: $ClientColor1;
          display: flex;
          // grid-template-columns: 32px auto;
          grid-gap: 10px;
          align-items: center;
          span.menulabel{
            padding: 5px 15px;
         }
        }

        span.menulabel:hover, &.selected span.menulabel{
          background: $ClientColor3;
          color: #ffffff;
          border-radius: 25px;
          padding: 5px 15px;
        }
      }
    }
    .bottom-menu {
      margin-bottom: 35px;
      ul {
        display: flex;
        flex-direction: column;

      }
    }
  }

  @media only screen and (max-width: 767px){
    .client-menu{
      box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.1);
      border: none;
    }
  }

  @media only screen and (min-width: 767px) {
    .client-menu {
      transform: translateX(0);
    }
    .menuButton {
      display: none;
    }
    .screenAdapter {
      margin-left: 304px;

      .selected-pan {
        left: 304px;
        width: calc(100% - 304px);
      }
    }
  }
}