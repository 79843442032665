@import './constant.scss';

.bvnV2 {
    .informationsClient__Container {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 50px;
    }

    .informationsClient__mainWrapper {
        min-height: calc(100vh - 310px);
    }

    .informationsClient__mainHeading {
        font-size: 28px;
        font-weight: 900;
        margin: 39px auto 14px auto !important;
        text-align: center;
        color: $ClientColor1;
    }

    hr.informationsClient__horizontalBar {
        border: 1px solid $ClientColor3;
        border-radius: 5px;
        width: 90px;
        margin: auto auto 50px auto;
    }

    .informationsClient__desc {
        font-size: 22px;
        text-align: center;
        text-transform: uppercase;
        color: $ClientColor1;
        max-width: 870px;
        margin: auto auto 60px auto !important;
    }

    .informationsClient__Container .formWrapper {
        width: 400px;
        margin: auto;
    }

    .mt-2 {
        margin-top: 20px;
    }

    .informationsClient__form {
        text-align: center;
        width: 400px;
        margin: auto;

        .categoryList, .cityList {
            border: 1px solid $ClientColor1;
            border-radius: 50px;
            height: 100%;
            min-height: 30px;
            margin: 0;
            margin-bottom: 15px;
            padding-left: 10px;
            color: $ClientColor3;
            font-size: 14px;
            text-align: center;
            width: 100%;
        }

        input[type=text] {
            min-height: 30px;
            display: block;
            border-radius: 50px;
            border: 1px solid $ClientColor1;
            text-align: center;
            margin: auto auto 15px auto;
            color: $ClientColor3;
            font-size: 14px;
            width: 100%;
        }

        input[type=text]::placeholder {
            color: $ClientColor3;
            font-size: 14px;
            font-weight: 400;
            opacity: 0.5;
            text-transform: uppercase;
        }

        input[type=checkbox] {
            width: 20px;
            height: 0px;
        }

        input[type="checkbox"]:checked::before {
            content: '';
            background: $ClientColor3;
            border-color: $ClientColor1;
        }

        input[type="checkbox"]::before {
            content: '';
            color: $ClientColor1;
            height: 20px;
            width: 20px;
            position: relative;
            display: block;
            border-radius: 4px;
            // left: -7px;
            border: 1px solid $ClientColor1;
            background: $ClientBackgroundColor;
        }

        .terms_and_conditions_wrapper {
            margin: auto;
            display: grid;
            grid-template-columns: 20px auto;
            grid-gap: 10px;
            margin-bottom: 15px;
        }

        .terms_and_conditions_wrapper label {
            color: $ClientColor1;
            text-align: left;
        }

        button.informationsClient__submitForm {
            border: none;
            border-radius: 50px;
            background: $TxtColor1;
            color: #ffffff;
            width: 200px;
            min-height: 30px;
            text-align: center;
            text-transform: uppercase;
            font-weight: 700;
            margin: 0;
        }
    }

    .profile_error {
        color: $red;
        text-align: center;
        margin-bottom: 20px;
        font-size: 16px;
    }

    .profile_message {
        color: $TxtColor2;
        text-align: center;
        margin-bottom: 20px;
        font-size: 16px;
    }

    button, input, optgroup, select, textarea {
        font-family: 'Roboto';
    }

    @media only screen and (max-width: 450px) {
        .informationsClient__mainHeading {
            font-size: 20px;
            margin: 12px auto 11px 24px !important;
            text-align: left;
        }
        hr.informationsClient__horizontalBar {
            margin-bottom: 29px;
        }
        .informationsClient__desc {
            font-size: 14px;
            margin: auto 15px 29px 15px !important;
        }
        .informationsClient__form {
            width: 80%;
        }
        .informationsClient__Container .formWrapper {
            width: 80%;
        }
        .terms_and_conditions_wrapper label {
            font-size: 12px;
        }
    }
}