.bvnV2 {
  .screen1Heading {
    margin: 25px auto 7px 13px !important;
    color: var(--partnerTxtColor4);
  }

  .screen1SubHeading {
    padding-bottom: 0px !important;
    margin-left: 13px !important;
    font-size: 18px;
    color: var(--partnerTxtColor3);
  }

  .adTitle {
    margin: 32px auto 0px 13px !important;
    font-size: 18px;
    color: var(--partnerTxtColor3);
  }

  button.previewBtn {
    transform: none;
  }

  .adCard {
    margin-top: 16px !important;
  }

  #offersWrap .adInnerCard {
    padding: 8px !important;
    margin-bottom: 0px !important;
  }

  .descriptionBox {
    margin-top: 32px !important;
    margin-left: 13px !important;
  }

  .descriptionHeading {
    padding-bottom: 2px !important;
    font-size: 18px;
  }

  .description {
    font-size: 14px;
    font-weight: bold;
    color: var(--partnerTxtColor3);
    line-height: 1.71 !important;
  }

  .infoBtnBox {
    margin: 16.5px 23.5px 0px 23.5px !important;
  }

  .infoBtn {
    font-size: 14px;
    text-align: center;
    color: var(--partnerTxtColor1);
    border: 2px solid var(--partnerTxtColor1);
    border-radius: 21px;
  }

  .screen1NextBtnBox {
    margin: 64px 23.5px 24.5px 23.5px !important;
  }
}