@import 'src/style/constant.scss';

.ImageZone {
  .dropzone {
    background: #fff;
    width: 300px;
    height: 134px;
    border: 0.5px solid #3c3939;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $ClientColor1;
    font-weight: 700;
    font-size: 13px;
    position: relative;

    .emptyBlock {
      border-radius: 14px;
      border: dotted 1px #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      svg {
        stroke: $ClientColor1;
      }

      span {
        position: absolute;
        bottom: 30px;
      }
    }
  }
  .imageBlock {
    position: relative;
    margin-top: 8px;

    button {
      border: none;
      position: absolute;
      top: -10px;
      right: -10px;
      border-radius: 20px;
      height: 22px;
      width: 22px;
      background: $ClientColor1;
      color: #fff;
      line-height: 10px;
      font-weight: 700;
      font-size: 10px;
    }
    img {
      border: 2px solid $ClientColor1;
      border-radius: 5px;
      object-fit: cover;
      width: 100%;
      max-height: 105px;
      transition: transform 0.25s ease-in-out;

      &:hover {
        transform: scale(2);
        border: 1px solid $ClientColor1;
      }
    }
  }
}