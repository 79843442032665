@import 'constant.scss';
@import 'common.scss';

.bvnV2 {
  :root {
    --content_width: 60%;
  }

  .activation {
    margin: 20px 0 0;
    max-width: 400px;

    button {
      padding: 12px 18px;
      border-radius: 50px;
      border: none;
      font-weight: bold;
      font-size: 13px;
      text-transform: uppercase;
      width: initial;
      height: initial;
    }

    & > div {
      color: $BtnColor1;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      margin-bottom: 15px;
    }

    input {
      border: solid $BtnColor1 1px;
      border-radius: 50px;
      padding: 12px 18px;
      font-weight: bold;
      font-size: 13px;
      text-transform: uppercase;
      margin-right: 15px;
      text-align: center;
    }
  }

  .my-tickets-container {
    width: 75%;
    margin: 0 auto;
    padding: 24px;
    padding-bottom: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ticoHeader {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 150px;
        height: auto;
        margin: 10px 0;
      }

      a {
        font-size: 16px;
        color: $ClientColor1;
        margin-top: 0;
        font-weight: bold;
        text-decoration: underline;
      }

      button {
        width: 200px;
        margin-top: 10px;
      }
    }

    .title {
      text-align: left;
      width: 100%;
      font-size: 20px;
      color: $ClientColor1;
    }

    .subtitle {
      margin-top: 15px;
      font-size: 18px;
      color: $ClientColor1;
    }

    .howToImage {
      width: 110%;
      height: 100%;
      max-width: 600px;
      margin-top: 20px;
    }

    .steps {
      display: flex;

      @media screen and (max-width: 550px) {
        width: calc(100% + 60px);
      }

      .step {
        flex: 1;
        margin: 0 10px;
        line-height: initial;

        .number {
          height: 50px;
          margin-bottom: 15px;
          margin-top: 20px;
          font-size: 50px;
          font-weight: bolder;
          color: var(--clientColor1);
          text-align: center;
        }

        div {
          @media screen and (max-width: 550px) {
            font-size: 0.8em;
          }
        }
      }
    }

    a {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: $ClientColor1;
      margin-top: 24px;
    }

    img {
      width: 72px;
      height: 22px;
    }

    p {
      text-align: center;
      margin-top: 20px !important;
    }

    hr {
      width: 90px;
      height: 2px;
      background: $ClientColor3;
      margin: auto;
    }

    .popupLink {
      text-align: left;
      margin-left: 50px;
      font-family: Roboto;

      svg {
        color: $ClientColor3;
        margin-bottom: -10px;
      }

      a {
        font-weight: 400;
        text-decoration: underline;
        color: black;
      }
    }

    .popupPhone {
      font-weight: 400;
      color: black;
      text-align: left;
      margin-left: 50px;
      font-family: Roboto !important;
      font-size: 16px;

      svg {
        color: $ClientColor3;
        margin-bottom: -10px;
      }
    }

    .popupRecapUsed {
      position: fixed;
      top: 100px;
      border: solid 1px $ClientColor3;
      background: $white;
      z-index: 999999;
      border-radius: 3px;
      -webkit-box-shadow: $boxShadow3;
      box-shadow: $boxShadow3;
      width: 100%;
      max-width: 500px;
      margin: auto;
    }
  }

  .ticketList {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 24px;
    justify-content: center;
    max-width: 900px;

    .explanation {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
  }

  .noTicket {
    height: 200px;
    align-items: center;
    color: $ClientColor1;
    font-size: 18px;
    font-weight: 500;
    margin-top: 24px;
  }

  .date {
    color: $TxtColor3;
    font-size: 32px;
    font-weight: bold;
  }

  .used {
    max-width: 100%;

    .TCATickets {
      cursor: default;
    }

    .ticketCard {
      height: 185px;
      width: 185px;
      border-radius: 10px;
      background: $BgColor2;
      padding: 16px;
      text-align: left;
      margin: 8px;
      margin-bottom: 8px;

      p {
        text-align: left;
        margin-top: 10px !important;
        color: #000000;
      }
    }

    h2 {
      font-family: Roboto !important;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      color: $TxtColor4;
      margin-top: 16px !important;
      padding-bottom: 0 !important;
    }
  }

  .ticketCard {
    min-height: 136px;
    width: 156px;
    border-radius: 10px;
    background: $BgColor2;
    padding: 11px;
    text-align: left;
    margin: 8px;
    margin-bottom: 8px;

    &.selected {
      box-shadow: 0px 4px 10px rgba(45, 51, 127, 0.2);
      background: #fff;
      border: 2px solid $TxtColor5;

      h1 {
        color: $TxtColor5;
      }
    }

    h1 {
      font-family: Roboto !important;
      font-style: normal;
      font-weight: bold;
      font-size: 40px !important;
      color: $TxtColor4;
      margin-top: 16px !important;
      padding-bottom: 0 !important;
    }

    .code {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: $TxtColor4;
      margin-top: 0;
      padding-bottom: 0;
    }

    p {
      text-align: left;
      margin-top: 0 !important;
    }
  }

  .TCTickets {
    min-height: 110px;
    width: 777px;
    max-width: 100%;
    border-radius: 17px;
    line-height: initial;
    font-size: 17px;
    font-weight: bold;
    align-items: center;
    display: flex;
    background-size: cover;
    margin-bottom: 10px;
    font-family: Draft;
    box-sizing: content-box;
    position: relative;
    cursor: pointer;
    color: #273680;

    .value {
      position: relative;
      top: 10px;
      line-height: 0.92;
      font-size: 90px;
      flex: 1;
      margin: 0px -30px;
    }

    .info {
      padding: 10px 0;
      margin: auto;
      font-family: Draft-regular;
      flex: 1;

      & > div {
        line-height: 1;
        font-size: 22px;
      }

      .couponCode {
        margin: auto;
        margin-top: 5px;
        border-radius: 17px;
        max-width: fit-content;
        padding: 5px 15px;
      }
    }

    .code {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      & > div {
        max-width: 150px;
        font-size: 22px;
        margin: auto 10px;
        text-align: center;
        padding-bottom: 5px;
        font-weight: normal;
      }

      .subtext {
        font-size: 18px;
        max-width: unset;
        padding: 0 5px;
      }
    }

    @media only screen and (max-width: 800px) {
      min-height: 80px;

      .value {
        width: 100px;
        font-size: 55px;

        @media only screen and (max-width: 340px) {
          font-size: 40px;
        }
      }


      .info > div {
        font-size: 15px;
      }
      .code {
        font-size: 14px;

        & > div {
          font-size: 14px;
        }

        .subtext {
          font-size: 12px;
        }
      }
    }
  }

  .TCATickets {
    min-height: 110px;
    width: 777px;
    max-width: 100%;
    border-radius: 17px;
    line-height: initial;
    font-size: 17px;
    font-weight: bold;
    align-items: center;
    display: flex;
    background-size: cover;
    margin-bottom: 10px;
    font-family: Roboto;
    box-sizing: content-box;
    position: relative;
    cursor: pointer;

    .value {
      position: relative;
      line-height: 0.92;
      font-size: 70px;
      width: 170px;

      &.promo {
        flex: 1;
      }

      .couponCode {
        font-size: 14px;
        font-weight: bold;
      }
    }

    .info {
      margin: 10px 10px 10px auto;
      flex: 1;
      text-align: left;

      & > div {
        line-height: 1;
        font-size: 20px;
      }
    }

    .evasion {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: auto;
        height: 80px;
      }
    }

    .code {
      max-width: unset;
      font-size: 25px;
      text-align: center;
      line-height: 1;
      border-left: solid;
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin: 5px 0;

      &.promo {
        flex: 2;
      }

      & > div {
        font-size: 25px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center;
        padding-bottom: 5px;
        font-weight: bold;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      .subtext {
        font-size: 18px;
        max-width: unset;
        padding: 0 5px;
      }
    }

    @media only screen and (max-width: 800px) {
      min-height: 80px;

      .value {
        width: 100px;
        font-size: 30px;

        .couponCode {
          font-size: 10px;
        }
      }


      .info > div {
        font-size: 12px;
      }

      .code {
        font-size: 14px;

        & > div {
          font-size: 14px;
        }

        .subtext {
          font-size: 12px;
        }
      }
    }
  }

  .coche {
    position: absolute;
    bottom: 2px;
    right: 2px
  }

  .bgBlur {
    opacity: 0.5;
  }

  .selected-pan {
    position: fixed;
    width: 100%;
    left: 0px;
    bottom: 0px;
    border-radius: 0px;

    .ticket-steps-wrapper {
      background: $ClientColor2;
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .coupon-exchange-wrapper {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-around;
      background: linear-gradient(90deg, #B8D2AF 56.75%, rgba(184, 210, 175, 0.56) 100%);
      min-height: 43px;
      grid-gap: 12px;
      padding: 0 7px;
      cursor: pointer;

      .left-container {
        display: flex;
        grid-gap: 7px;
        align-items: center;

        p {
          max-width: 190px;
          font-size: 8px;
          color: rgba(0, 0, 0, 0.65);
          padding: 0;
          margin: 0 !important;
        }

        .font-weight-bold {
          font-weight: bold;
        }
      }

      .right-container {
        display: flex;
        grid-gap: 12px;
        align-items: center;

        button {
          max-width: 80px;
          width: 100%;
          min-height: 30px;
          border: none;
          background: #589442;
          border-radius: 3px;
          font-weight: bold;
          font-size: 7px;
          text-transform: uppercase;
          margin: 0 !important;
          padding: 0 !important;
          line-height: normal;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }

    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: $ClientColor1;
      margin-top: 22px !important;
    }

    .ticket-steps {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.2em;
      color: $ClientColor1;
      margin-top: 16px;
    }

    .step1-actions {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin-top: 0 !important;

        &:first-child {
          margin-top: 22px !important;
        }
      }
    }
  }

  .confirm-popup,
  .selected-pan {
    // Making it shareable
    button {
      margin-top: 10px;
    }

    .step2-actions {
      display: flex;

      button {
        width: 155px;
      }

      button:nth-child(2) {
        margin-left: 16px;
      }
    }
  }

  .confirmed-ticket {
    background: transparent;
    position: static;
    color: $ClientColor1;
    min-height: 600px;

    .sellerName {
      font-size: 32px;
      line-height: 37px;
      font-weight: bold;
    }

    .detail {
      background: $ClientColor2;
      border-radius: 10px;
      color: $ClientColor1;
      font-size: 18px;
      line-height: 21px;
      margin-top: 20px;
      width: 100%;
      max-width: 400px;
      padding-top: 10px;

      .smaller {
        font-size: 14px;
        line-height: 16px;
      }

      & > div::first-letter {
        text-transform: uppercase;
      }


      .data {
        color: $ClientColor3;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }

    .explaination {
      font-size: 25px;
      font-weight: bold;
      margin-top: 10px;
    }

    .close {
      background: $BgColor2;
      color: $TxtColor4;
    }

    a {
      margin: 0;
    }

    button {
      width: 210px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }

    .refundBlock {
      font-size: 23px;
      line-height: 21px;
      margin-top: 20px;

      .separator {
        border-bottom: 2px solid $ClientColor1;
        width: 100px;
        margin: 0 auto;
        margin-bottom: 10px;
      }

      span {
        text-decoration: underline;
      }
    }
  }

  .ticket-image {
    width: 100%;
    max-width: 400px;
    margin-top: 24px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .used-tickets {
    background: #de2424;
    box-shadow: 0px 10px 14px rgba(222, 36, 36, 0.2);
    border-radius: 10px;
    max-width: 249px;
    padding: 16px;
    margin-top: -30px;

    p {
      width: auto;
      font-family: Roboto !important;
      font-style: normal;
      font-weight: normal;
      font-size: 14px !important;
      line-height: 16px;
      text-align: center;
      margin-top: 0 !important;
      color: $BtnTxtColor1;
    }

    a {
      border: 1px solid $BtnTxtColor1;
      border-radius: 50px;
      padding: 7px 18px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $BtnTxtColor1;
      display: inline-block;
      margin-top: 16px;
    }
  }

  .confirm-popup {
    position: relative;
    background: $white;
    z-index: 999999;
    line-height: 100px;
    border-radius: 16px 16px 0 0;
    -webkit-box-shadow: var(--boxShadow3);
    box-shadow: var(--boxShadow3);
    position: fixed;
    top: 50%;
    left: 50%;
    width: 40%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    align-items: center;
  }

  .bg-liteGreen {
    background: #589442 !important;
  }

  .text-liteGreen {
    color: #589442 !important;
  }

  .h-full {
    min-height: calc(100vh - 75px);
  }

  .confirmExchangeBtn {
    border: none !important;
    background-color: #ffffff !important;
  }

  .stepBackBtn {
    border: 1px solid #ffffff !important;
    background: transparent !important;
    color: #ffffff !important;
  }

  .ticketsExchangeTextWrapper p {
    color: #ffffff;
  }

  @media only screen and (min-width: 541px) {
    .selected-pan {
      position: fixed;
      width: 100%;
      left: 0px;
      bottom: 0px;
      border-radius: 0px;

      .ticket-steps-wrapper {
        background: $ClientColor2;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .coupon-exchange-wrapper {
        min-height: 60px;
        justify-content: center;

        .left-container {
          p {
            max-width: 300px;
            font-size: 12px;
          }
        }

        .right-container {
          button {
            max-width: 100px;
            min-height: 35px;
            font-size: 10px;
          }
        }
      }
    }
  }

  /*  Medium devices (tablets) */
  @media only screen and (max-width: 800px) {
    .my-tickets-container {
      width: 95%;
    }
    .confirm-popup button {
      width: 100px;
    }
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 500px) {
    .ticketCard {
      width: 48%;
      margin: 1%;
    }

    .confirm-popup {
      width: 60%;
    }

    .confirm-popup button {
      width: 50px;
    }

    .ticket-image {
      margin-bottom: -55px;
    }
  }
}

/* Small height devices (with keyboard) */
@media only screen and (max-height: 750px) {
  .my-tickets-container {
    padding-bottom: 100px;
  }
  .activation {
    input {
      margin-bottom: 15px;
    }
  }
  .subtitle {
    font-size: 15px !important;
  }
  .selected-pan {
    position: static;
    .ticket-steps-wrapper {
      padding: 10px;
      p {
        font-size: 13px !important;

        &:first-child {
          margin-top: 10px !important;
        }
      }
      button {
        font-size: 13px !important;
        padding: 5px;
        width: 100px;
      }
    }

    .ticket-steps {
      line-height: 12px;
    }
  }
}
