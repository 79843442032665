@import './constant.scss';

@font-face {
  font-family: Draft;
  src: url(https://cdn.cibler.io/fonts/DraftG-Bold.woff);
  font-weight: normal;
}

@font-face {
  font-family: Draft-thin;
  src: url(https://cdn.cibler.io/fonts/DraftG-Thin.ttf);
  font-weight: normal;
}

@font-face {
  font-family: Draft-regular;
  src: url(https://cdn.cibler.io/fonts/DraftG-Regular.ttf);
  font-weight: normal;
}

@media (max-width: 350px) {
  .mobile-menu {
    font-size: $baseFontSize !important;
  }

  .top-bar button {
    padding: 11px 4px !important;
  }
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}


// #som_new_user_pass_again,
// #som_new_user_pass {
//   border: none !important;
//   border-radius: var(--borderRadius);
//   -webkit-box-shadow: 34px 34px 89px 0 rgba(58, 69, 129, 0.13);
//   box-shadow: 34px 34px 89px 0 rgba(58, 69, 129, 0.13);
//   outline: none;
//   height: 55px;
//   line-height: 55px;
//   padding: 0 34px !important;
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//   width: 100%;
// }

/*
  =============
  Common
  =============
  */

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.text-primary {
  color: $primaryColor;
}

.text-trademark {
  color: $trademarkColor;
}

.text-center {
  text-align: center;
}

p {
  line-height: 1.4 !important;
  font-weight: normal;
  margin: 0 !important;
  padding-bottom: 1em;
}

small {
  font-size: smaller !important;
}

.mt-1 {
  margin-bottom: 10px;
}

.mt-2 {
  margin-bottom: 20px;
}

.mt-3 {
  margin-bottom: 30px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px !important;
}

hr {
  background: $background-grey;
  border: none;
  height: 1px;
}

::-webkit-input-placeholder {
  color: $placeholderColor;
  opacity: 1;
}

::-moz-placeholder {
  color: $placeholderColor;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $placeholderColor;
  opacity: 1;
}

::-ms-input-placeholder {
  color: $placeholderColor;
  opacity: 1;
}

::placeholder {
  color: $placeholderColor;
  opacity: 1;
}

html,
body {
  overflow-x: hidden;
}

// .loader-wrap {
//   text-align: center;
// }

// .loader {
//   border: 4px solid var(--primaryLighterColor);
//   border-radius: 50%;
//   border-top: 4px solid var(--primaryColor);
//   width: 40px;
//   height: 40px;
//   -webkit-animation: spin 2s linear infinite;
//   animation: spin 2s linear infinite;
//   margin: 10px auto;
// }

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.logoImage{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.logoContainer{
  width: 185px;
  padding: 15px;
}
/*
  =============
  Icons
  =============
  */
.icon {
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-size: contain;
  vertical-align: middle;
}

.icon-md {
  width: 18px;
  height: 18px;
}

.icon.start {
  margin-right: 7px;
}

.icon.end {
  margin-left: 7px;
}

.icon-white {
  -webkit-filter: brightness(10);
  filter: brightness(10);
}

.icon-black {
  -webkit-filter: brightness(0);
  filter: brightness(0);
}

.icon-star-filled {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.515 10'%3E%3Cdefs%3E%3C/defs%3E%3Cpath fill='%23d84148' d='M5.258 0l1.625 3.292 3.633.528-2.629 2.562L8.508 10 5.259 8.292 2.01 10l.621-3.618L.002 3.82l3.633-.528z'%3E%3C/path%3E%3C/svg%3E");
}

.icon-hamburger {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 14'%3E%3Cdefs/%3E%3Cpath fill='%23e02020' d='M0 14v-2h10v2zm0-6V6h16v2zm0-6V0h16v2z'/%3E%3C/svg%3E");
}

.icon-arrow-left {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg id='left_arrow' data-name='left arrow' transform='translate(16 16) rotate(-180)'%3E%3Cpath id='Tracé_10' data-name='Tracé 10' d='M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z' fill='%233a4581'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.icon-arrow-left.icon-white {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg id='left_arrow' data-name='left arrow' transform='translate(16 16) rotate(-180)'%3E%3Cpath id='Tracé_10' data-name='Tracé 10' d='M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z' fill='%23ffffff'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.icon-arrow-right {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg id='left_arrow' data-name='left arrow' transform='translate(16 16) rotate(-180)'%3E%3Cpath id='Tracé_10' data-name='Tracé 10' d='M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z' fill='%233a4581'/%3E%3C/g%3E%3C/svg%3E%0A");
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-arrow-right.icon-white {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg id='left_arrow' data-name='left arrow' transform='translate(16 16) rotate(-180)'%3E%3Cpath id='Tracé_10' data-name='Tracé 10' d='M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z' fill='%23ffffff'/%3E%3C/g%3E%3C/svg%3E%0A");
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-clear {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.936' height='21.936' viewBox='0 0 21.936 21.936'%3E%3Cg id='Close' transform='translate(1 1)'%3E%3Ccircle id='Oval' cx='9.968' cy='9.968' r='9.968' stroke-width='2' transform='translate(0 0)' stroke='%239399ba' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' fill='none'/%3E%3Cpath id='Path' d='M0,0,5.981,5.981' transform='translate(6.978 6.978)' fill='none' stroke='%239399ba' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3Cpath id='Path-2' data-name='Path' d='M0,5.981,5.981,0' transform='translate(6.978 6.978)' fill='none' stroke='%239399ba' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.icon-close {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.936' height='21.936' viewBox='0 0 21.936 21.936'%3E%3Cg id='Close' transform='translate(1 1)'%3E%3Cpath id='Path' d='M0,0,5.981,5.981' transform='translate(6.978 6.978)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3Cpath id='Path-2' data-name='Path' d='M0,5.981,5.981,0' transform='translate(6.978 6.978)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
}

.icon-close-grey {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.936' height='21.936' viewBox='0 0 21.936 21.936'%3E%3Cg id='Close' transform='translate(1 1)'%3E%3Cpath id='Path' d='M0,0,5.981,5.981' transform='translate(6.978 6.978)' fill='none' stroke='%23BCC0D4' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3Cpath id='Path-2' data-name='Path' d='M0,5.981,5.981,0' transform='translate(6.978 6.978)' fill='none' stroke='%23BCC0D4' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
}

.icon-glass {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.061 24.839'%3E%3Cdefs/%3E%3Cg fill='none' stroke='%23bfbfbf' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2' data-name='Atoms/ Icons/ Search Inactive' transform='translate(1 1)'%3E%3Ccircle cx='8.28' cy='8.28' r='8.28'/%3E%3Cpath d='M11.96 15.64l6.44 6.44a2.61 2.61 0 003.68 0h0a2.61 2.61 0 000-3.68l-6.44-6.44'/%3E%3C/g%3E%3C/svg%3E");
}

.icon-export {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg id='Export' transform='translate(0 16) rotate(-90)'%3E%3Crect id='Rectangle_570' data-name='Rectangle 570' width='16' height='16' fill='none'/%3E%3Cpath id='Tracé_151' data-name='Tracé 151' d='M1.655,1.655v12.69H9.563V16H0V0H9.563V1.655ZM9.011,13.241,7.724,11.954l2.943-2.943h-6.8V7.172h6.989L7.724,4.23,9.011,2.943l4.966,4.966Z' transform='translate(2)' fill='%233a4581'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.icon-avatar {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath id='person' d='M0,370v-2c0-2.2,3.6-4,8-4s8,1.8,8,4v2Zm4-12a4,4,0,1,1,4,4A4,4,0,0,1,4,358Z' transform='translate(0 -354)' fill='rgba(58,69,129,0.8)'/%3E%3C/svg%3E");
}

.icon-credit {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27.737' height='20' viewBox='0 0 27.737 20'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:%233a4581%7D %3C/style%3E%3C/defs%3E%3Cg id='Credit_Card' transform='translate(-2.5 -15.8)'%3E%3Cg id='Groupe_233' transform='translate(2.5 15.8)'%3E%3Cpath id='Tracé_555' d='M26.85 15.8H5.887A3.327 3.327 0 0 0 2.5 19.041v1.944h27.737v-1.944A3.327 3.327 0 0 0 26.85 15.8z' class='cls-1' transform='translate(-2.5 -15.8)'/%3E%3Cpath id='Tracé_556' d='M2.5 54.6a3.332 3.332 0 0 0 3.387 3.27H26.85a3.332 3.332 0 0 0 3.387-3.27v-7.1H2.5z' class='cls-1' transform='translate(-2.5 -37.87)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.icon-energy {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.989' height='18' viewBox='0 0 10.989 18'%3E%3Cg id='Energy' transform='translate(-7.965 -1.64)'%3E%3Cpath id='Tracé_667' data-name='Tracé 667' d='M18.886,7.915,11.116,19.42a.5.5,0,0,1-.412.22.55.55,0,0,1-.187-.033.5.5,0,0,1-.3-.549l1.374-7.907h-3.1a.511.511,0,0,1-.4-.2.516.516,0,0,1-.1-.467L10.6,2a.5.5,0,0,1,.478-.357h5.176a.516.516,0,0,1,.407.209.489.489,0,0,1,.066.451L15.078,7.134h3.379a.5.5,0,0,1,.412.78Z' transform='translate(0)' fill='%230091ff'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.icon-fire {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.003' height='17.999' viewBox='0 0 14.003 17.999'%3E%3Cpath id='fire' d='M17.66,11.2a8.573,8.573,0,0,0-.77-.82c-.67-.6-1.43-1.03-2.07-1.66A4.979,4.979,0,0,1,13.95,3a6.49,6.49,0,0,0-2.49,1.32,7.9,7.9,0,0,0-2.39,8.9.844.844,0,0,1,.08.33.554.554,0,0,1-.35.5.62.62,0,0,1-.66-.12A.579.579,0,0,1,8,13.76a5,5,0,0,1-.55-5.12A7.125,7.125,0,0,0,5,14.47a7.223,7.223,0,0,0,.29,1.5A6.709,6.709,0,0,0,6,17.7a7,7,0,0,0,4.96,3.22,7.559,7.559,0,0,0,6.07-1.6,5.923,5.923,0,0,0,1.53-6.6l-.13-.26a11.213,11.213,0,0,0-.77-1.26M14.5,17.5a3.276,3.276,0,0,1-1.1.6,2.855,2.855,0,0,1-2.9-.82,2.774,2.774,0,0,0,2.11-2.05A5.643,5.643,0,0,0,12.33,13a3.7,3.7,0,0,1,.17-2.06A6.056,6.056,0,0,0,13.13,12c.77,1,1.98,1.44,2.24,2.8a1.54,1.54,0,0,1,.06.43,3.055,3.055,0,0,1-.93,2.27Z' transform='translate(-4.988 -3)' fill='%23fa6400'/%3E%3C/svg%3E%0A");
}

.icon-caret {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.811 4.75'%3E%3Cdefs/%3E%3Cpath fill='%233a4581' d='M7.564 1.442A.845.845 0 006.369.247L3.907 2.711 1.444.247A.845.845 0 00.249 1.442l3.058 3.062a.844.844 0 001.194 0z'/%3E%3C/svg%3E");
}

.icon-save {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cpath id='content-save' fill='%23fff' d='M15 9H5V5h10m-3 14a3 3 0 1 1 3-3 3 3 0 0 1-3 3m5-16H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V7z' transform='translate(-3 -3)'/%3E%3C/svg%3E%0A");
}

/* Buttons */

.badge {
  padding: 8px 18px;
  border-radius: $borderRadius;
  color: $white;
}

.badge.badge-primary {
  background: $primaryColor;
}

.badge.badge-trademark {
  background: $trademarkColor;
}

.btn {
  background: none;
  outline: none;
  padding: $btnPadding;
  -webkit-box-shadow: $baseBoxShadow;
  box-shadow: $baseBoxShadow;
  border-radius: $borderRadius;
  font-family: $fontFamily;
  font-size: $baseFontSize;
  display: block;
  width: 100%;
  cursor: pointer;
  font-weight: $fontWeightBold;
  text-shadow: none;
  border-color: transparent;
}

.btn[disabled],
.btn-primary[disabled],
.btn-secondary[disabled] {
  pointer-events: none;
  opacity: 0.4;
}

a.btn {
  text-align: center;
}

.btn-link {
  color: $primaryColor;
  padding: 0;
  border: none;
}

.btn-inline {
  display: inline-block;
  width: auto;
}

.btn-sm {
  border-radius: $borderRadiusSmall;
  padding: $btnPaddingSmall;
}

.btn-flat {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn .icon svg {
  width: 10px;
}

.btn .icon.start svg {
  margin-right: 10px;
}

.btn .icon.end svg {
  margin-left: 10px;
}

.btn-primary{
  color: $primaryAccent;
  background: $BtnColor1;
  border: 2px solid $BtnColor1;
}

.btn-primary.btn-outlined {
  border: 2px solid $primaryAccent;
}

.btn-primary.btn-light {
  color: $primaryColor;
  background: $primaryLighterColor;
  border: 2px solid $primaryLighterColor;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-secondary {
  color: $primaryColor;
  background: none;
  border: 2px solid $primaryColor;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-secondary.active {
  background: $primaryLighterColor;
}

.btn-primary .icon svg {
  fill: $primaryColor;
}

.btn-red {
  color: $white;
  background: $BtnRed;
}

.mode-magasin {
  color: $secondaryColor !important;
  border-color: rgba(58, 69, 129, 0.08) !important;
  flex-basis: 315px;
}

.mode-magasin .icon svg {
  fill: $secondaryColor !important;
}

.btn-trademark {
  color: $primaryAccent;
  background: $trademarkColor;
}

.btn-group {
  display: flex;
  justify-content: space-between;
}

.btn-group .btn {
  flex: 0 0 49%;
}

/* Cards */
.offer span {
  font-size: 13px;
}

.card {
  background: $primaryAccent;
  border-radius: $borderRadius;
  -webkit-box-shadow: $baseBoxShadow;
  box-shadow: $baseBoxShadow;
  padding: 16px 24px !important;
  margin-bottom: 8px;
}

.card.card-offer {
  cursor:pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -ms-flex-pack: justify;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: nowrap;
}

.formBody p {
  padding-bottom: 1em !important;
}

img {
  max-width: 100%;
  height: auto;
}

.card.card-offer p {
  padding: 0;
}

.card.card-offer.inactive p {
  opacity: 0.65;
}

.card.card-offer .date-until {
  font-size: 12px;
}

.card.card-offer .offer {
  color: $primaryColor;
  font-weight: $fontWeightBold;
  line-height: 1.14;
}

.card.card-offer .category {
  font-size: 10px;
  font-weight: 300;
  text-transform: uppercase;
}
.flex-equal > * {
  flex: 1;
}
.card.card-client {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: $primaryLighterColor;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.card.card-client.red {
  background: $background-secondary;
}

.card.card-client.red p {
  color: $white;
}

.card.card-client p {
  color: #3a4581;
  padding: 0;
}

.card.card-client .points {
  margin-left: auto;
  text-align: center;
}

.points strong {
  font-size: 22px;
}

.avatar-wrap {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 16px;
  background: $white;
  position: relative;
}

.avatar-wrap .icon-avatar {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
}

.avatar-wrap img {
  position: relative;
  z-index: 1;
}

/* Toggle Switch */

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $primaryAccent;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 8px 8px 21px 0 rgba(50, 157, 156, 0.13);
  box-shadow: 8px 8px 21px 0 rgba(50, 157, 156, 0.13);
}

.slider:before {
  position: absolute;
  content: "";
  width: 23px;
  height: 22px;
  left: 4px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background-color: $trademarkColor;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $trademarkColor;
}

/* input:focus+.slider {
    -webkit-box-shadow: 0 0 1px var(--trademarkColor);
    box-shadow: 0 0 1px var(--trademarkColor);
  } */

input:checked + .slider:before {
  -webkit-transform: translate(17px, -50%);
  -ms-transform: translate(17px, -50%);
  transform: translate(17px, -50%);
  background-color: $primaryAccent;
}

.slider.round {
  border-radius: 16px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Tabs */

.tabs .tab-buttons .btn-tab {
  margin-right: 10px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.tabs .tab-buttons .btn-tab.active {
  color: $primaryLightColor;
}

.tabs .tab-buttons .btn-tab.alwaysActive {
  color: $primaryLightColor;
}

.tabs .tab-buttons .btn-tab.alwaysBlue {
  color: $primaryColor;
}

.tabs .tab-content {
  padding: 24px 0;
}

.tabs .tab-content:not(.active) {
  display: none;
}

/* Form elements */
.form-control {
  border: none !important;
  border-radius: $borderRadius;
  -webkit-box-shadow: $baseBoxShadow;
  box-shadow:  $baseBoxShadow;
  outline: none;
  height: 55px;
  line-height: 55px;
  padding: 0 34px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  font-family: $fontFamily;
  font-weight: $fontWeightBold;
  color: $primaryColor;
  font-size: 15px;
}

textarea.form-control {
  height: auto;
  resize: none;
}

.form-control.underlined {
  box-shadow: none;
  padding: 3px !important;
  border-bottom: 1px solid rgba(58, 69, 129, 0.5) !important;
  height: 30px;
  line-height: 30px;
  width: auto;
  border-radius: 0;
  background: none;
}

.form-control.underlined:focus {
  border-bottom: 1px solid $primaryColor !important;
}

.form-control.has-icon {
  background-repeat: no-repeat;
  background-position: 22px 50%;
  padding-left: 66px !important;
}

.form-control + small {
  margin-top: 8px;
  padding-left: 15px;
}

select.from-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.811 4.75'%3E%3Cdefs/%3E%3Cpath fill='%233a4581' d='M7.564 1.442A.845.845 0 006.369.247L3.907 2.711 1.444.247A.845.845 0 00.249 1.442l3.058 3.062a.844.844 0 001.194 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 34px) center;
  background-size: 12px 12px;
}

.form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  margin-bottom: 20px;
}

.form-group label {
  font-size: $baseFontSize;
  margin-bottom: 5px;
  padding-left: 20px;
  font-weight: 400;
  color: $primaryColor;
}

.form-group.white-text label {
  color: $primaryAccent;
}

.form-group.form-inline {
  flex-flow: row nowrap;
  align-items: center;
}

.form-group.form-inline > input {
  margin-left: 12px;
}

.form-group.form-inline > div {
  margin-left: 12px;
  display: flex;
  flex-flow: column nowrap;
}

.form-group.form-inline.nopad label {
  margin-bottom: 0;
  padding-left: 0;
}

.form-group.form-inline > div:first-child {
  padding-left: 0;
}

.form-group.form-inline > div:last-child {
  padding-right: 0;
}

.input-group {
  position: relative;
}

.input-group .icon {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}

.input-group.icon-left .icon {
  right: auto;
  left: 36px;
}

.input-group .form-control {
  padding-left: 80px;
}

.check-main-block {
  position: relative;
  display: inline-block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.check-main-block label {
  cursor: pointer;
  margin: 0;
  display: inline-block;
}

.check-main-block input {
  cursor: pointer;
  opacity: 0;
  display: none;
  width: 0;
  height: 0;
}

.checklist-checkboxtext {
  margin-left: 15px;
  margin-right: 0;
  vertical-align: middle;
  font-size: $baseFontSize;
  color: $primaryColor;
  float: left;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid $primaryColor;
  border-radius: 6px;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.check-main-block:hover input ~ .checkmark,
.check-main-block input:focus ~ .checkmark {
  background-color: transparent;
  border: 1px solid #005896;
}

.check-main-block input:checked:focus ~ .checkmark {
  border: 1px solid #005896;
}

.check-main-block input[type="radio"] ~ .checkmark:after {
  background-image: none;
}

.check-main-block input[type="radio"] ~ .checkmark {
  border-radius: 100%;
  width: 24px;
  height: 24px;
}

.check-main-block input[type="radio"]:checked ~ .checkmark:after {
  border-radius: 100%;
  background: $primaryColor;
  width: 60%;
  height: 60%;
}

.checkmark:after {
  content: "";
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: $primaryColor;
  border: 1px solid $primaryColor;
  border-radius: 6px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24' style='&%2310; fill: white;&%2310;'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
  background-position: center;
  background-size: 80%;
}

.check-main-block input:checked ~ .checkmark:after {
  opacity: 1;
}

/* Dashboard Layout */

.dashboard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  // font-family: var(--fontFamily);
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: $basePadding;
  background: $pagebg;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 50px;
  min-height: calc(100vh - 137px);
}

#sidebar {
  padding: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-shadow: 0 15px 18px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 15px 18px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-flex: 0;
  -ms-flex: 0 1 23%;
  flex: 0 1 23%;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background: var(--secondaryColor);
  display: none;
  max-width: 850px;
  margin: 0 auto;
  left: 50% !important;
  transform: translateX(-50%);
}

#sidebar.expand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 0;
  left: 0;
}

#sidebarClose {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-size: contain;
  cursor: pointer;
}

#sidebar nav ul {
  list-style: none;
  padding: 0;
}

#sidebar nav ul li:first-child {
  margin-top: 0;
}

#sidebar nav ul li {
  margin-bottom: 25px;
}

#sidebar nav ul li:last-child {
  margin-bottom: 0;
}

#sidebar nav ul li a {
  text-decoration: none;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.38px;
  color: white;
  position: relative;
  display: block;
  line-height: 1;
}

#sidebar nav ul li a small {
  font-size: 14px;
  font-weight: 400;
}

#sidebar nav ul li a:hover {
  color: white !important;
}

#sidebar nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.main {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 850px;
  margin: auto;
  width: 100%;
}

.top-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}

.top-bar > .btn {
  white-space: nowrap;
}

.mobile-menu {
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  margin: 0 auto;
  color: var(--primaryColor);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-menu label {
  margin-right: 10px;
  cursor: pointer;
  width: 42px;
  height: 42px;
  text-align: center;
  line-height: 35px;
  background-size: 16px;
  background-color: white;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  -webkit-box-shadow: 0 3px 6px 0 rgba(58, 69, 129, 0.13);
  box-shadow: 0 3px 6px 0 rgba(58, 69, 129, 0.13);
}

.mobile-menu input {
  display: none;
}


/* Maps */
.mapboxgl-ctrl-geocoder {
  background-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-align: center !important;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--input {
  border-radius: 0;
}

#customer_login h2 {
  display: none;
}

#customer_login .u-column2.col-2 {
  display: none;
}

#customer_login .u-column1.col-1 {
  float: none;
  margin: 0 auto;
}

/*Hide menu*/

#page-container {
  padding-top: 0px !important;
}

#main-header {
  display: none;
}

.hexagon-clip {
  width: 120px;
  height: 120px;
  display: inline-block;
  background: white;
  clip-path: polygon(
    45% 1.33975%,
    46.5798% 0.60307%,
    48.26352% 0.15192%,
    50% 0%,
    51.73648% 0.15192%,
    53.4202% 0.60307%,
    55% 1.33975%,
    89.64102% 21.33975%,
    91.06889% 22.33956%,
    92.30146% 23.57212%,
    93.30127% 25%,
    94.03794% 26.5798%,
    94.48909% 28.26352%,
    94.64102% 30%,
    94.64102% 70%,
    94.48909% 71.73648%,
    94.03794% 73.4202%,
    93.30127% 75%,
    92.30146% 76.42788%,
    91.06889% 77.66044%,
    89.64102% 78.66025%,
    55% 98.66025%,
    53.4202% 99.39693%,
    51.73648% 99.84808%,
    50% 100%,
    48.26352% 99.84808%,
    46.5798% 99.39693%,
    45% 98.66025%,
    10.35898% 78.66025%,
    8.93111% 77.66044%,
    7.69854% 76.42788%,
    6.69873% 75%,
    5.96206% 73.4202%,
    5.51091% 71.73648%,
    5.35898% 70%,
    5.35898% 30%,
    5.51091% 28.26352%,
    5.96206% 26.5798%,
    6.69873% 25%,
    7.69854% 23.57212%,
    8.93111% 22.33956%,
    10.35898% 21.33975%
  );
  padding: 3px;
}

.hexagon-clip img {
  width: 100%;
  height: 100%;
  clip-path: polygon(
    45% 1.33975%,
    46.5798% 0.60307%,
    48.26352% 0.15192%,
    50% 0%,
    51.73648% 0.15192%,
    53.4202% 0.60307%,
    55% 1.33975%,
    89.64102% 21.33975%,
    91.06889% 22.33956%,
    92.30146% 23.57212%,
    93.30127% 25%,
    94.03794% 26.5798%,
    94.48909% 28.26352%,
    94.64102% 30%,
    94.64102% 70%,
    94.48909% 71.73648%,
    94.03794% 73.4202%,
    93.30127% 75%,
    92.30146% 76.42788%,
    91.06889% 77.66044%,
    89.64102% 78.66025%,
    55% 98.66025%,
    53.4202% 99.39693%,
    51.73648% 99.84808%,
    50% 100%,
    48.26352% 99.84808%,
    46.5798% 99.39693%,
    45% 98.66025%,
    10.35898% 78.66025%,
    8.93111% 77.66044%,
    7.69854% 76.42788%,
    6.69873% 75%,
    5.96206% 73.4202%,
    5.51091% 71.73648%,
    5.35898% 70%,
    5.35898% 30%,
    5.51091% 28.26352%,
    5.96206% 26.5798%,
    6.69873% 25%,
    7.69854% 23.57212%,
    8.93111% 22.33956%,
    10.35898% 21.33975%
  );
}
.cc-revoke {
  display: none !important;
}

:focus {
  // border: none !important;
  outline: none !important;
}
button:active {
  -webkit-transform: scale3d(0.9, 0.9, 1);
  transform: scale3d(0.9, 0.9, 1);
}

.magasinBody {
  background-color: $secondaryColor !important;
}

.star {
  color: $TxtColor2;
}
.p-0 {
  padding: 0px !important;
}
.p-2 {
  padding: 2px !important;
  position: relative;
}
.pt-2 {
  padding-top: 2px !important;
}
.pt-16 {
  padding-top: 16px !important;
}
.pt-21 {
  padding-top: 21px !important;
}
.pt-25 {
  padding-top: 25px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pl-1 {
  padding-left: 1px !important;
}
.pl-2 {
  padding-left: 2px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pl-16 {
  padding-left: 16px !important;
}
.pl-17 {
  padding-left: 17px !important;
}
.pr-16 {
  padding-right: 16px !important;
}
.pr-17 {
  padding-right: 17px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.m-0 {
  margin: 0px !important;
}
.mr-0 {
  margin-right: 0px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.ml-0 {
  margin-left: 0px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-6 {
  margin-left: 6px !important;
}
.ml-7 {
  margin-left: 7px !important;
}
.ml-11 {
  margin-left: 11px !important;
}
.ml-12 {
  margin-left: 12px !important;
}
.ml-13 {
  margin-left: 13px !important;
}
.ml-21 {
  margin-left: 21px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-26 {
  margin-bottom: 26px !important;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.mb-33 {
  margin-bottom: 33px !important;
}
.mb-37 {
  margin-bottom: 37px !important;
}
.mb-27 {
  margin-bottom: 27px !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-6 {
  margin-top: 6px !important;
}
.mt-7 {
  margin-top: 7px !important;
}
.mt-11 {
  margin-top: 11px !important;
}
.mt-12 {
  margin-top: 12px !important;
}
.mt-13 {
  margin-top: 13px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mt-21 {
  margin-top: 21px !important;
}
.mt-23 {
  margin-top: 23px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-26 {
  margin-top: 26px !important;
}
.mt-32 {
  margin-top: 32px !important;
}
.mt-33 {
  margin-top: 33px !important;
}
.mt-37 {
  margin-top: 37px !important;
}
.mt-27 {
  margin-top: 27px !important;
}
.mt-197 {
  margin-top: 197px !important;
}
