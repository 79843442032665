@import 'constant.scss';
@import 'custom.scss';

.bvnV2 {
  :root {
    --content_width: 60%;
  }

  $char-w: 1ch;
  $gap: .7 * $char-w;
  $n-char: 7;
  $in-w: $n-char * ($char-w + $gap);

  .tickets-consumer {
    width: 350px;
    min-height: 550px;
    margin: 0 auto;
    padding: 24px;
    text-align: center;
    color: $ClientColor1;
  }

  .tickets-consumer-title {
    margin-bottom: 40px !important;
    color: $ClientColor1;
  }

  .ticket {
    margin: 50px 0 25px;
    text-align: center;
    justify-content: center;
    position: relative;
    scroll-margin-top: 200px;

    input {
      display: block;
      margin: auto;
      border: none;
      padding: 0;
      font-family: Roboto;
      width: 103px;
      border-bottom: solid 2px #000;
      padding-bottom: 8px;
      text-align: center;
      background: none;
      scroll-margin-top: 200px;

      &::placeholder {
        font-size: 1em;
      }
    }

    .ticketInfoWrapper {
      position: absolute;
      right: 26px;
      bottom: -16px;
      cursor: pointer;
      color: $ClientColor1;

      span {
        font-size: 10px;
        font-weight: 700;
        text-decoration: underline
      }
    }
  }

  .ticket-detail {
    padding-top: 20px;
    text-align: center;

    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #000000;
    }
  }

  .ticket-redirection {
    color: $ClientColor1;
    text-decoration: underline;
  }

  @media only screen and (max-height: 550px) {
    .ticket {
      margin-top: 10px;
      margin-bottom: 0;
    }

    .tickets-consumer-title {
      margin-bottom: 10px !important;
    }
  }
}