@import 'constant.scss';

.signinWrapper {
  background-color: $pagebg;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  form {
    background-color: orange;
    padding: 30px;
    width: 50vw;
    height: 200px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    input {
      height: 30px;
      border-radius: 10px;
      &:focus {
        border: $TxtColor1 2px solid;
      }
    }
  }
}
