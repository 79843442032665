@import "constant";

.bvnV2 {
  #main-header {
    display: none;
  }

  #popupHistory {
    position: fixed;
    background: white;
    z-index: 999999;
    bottom: 0;
    padding: 15px 25px 30px 30px;
    border-radius: 16px 16px 0 0;
    -webkit-box-shadow: var(--boxShadow3);
    box-shadow: var(--boxShadow3);
    overflow-y: scroll;
    height: 100%;
    width: 100%;
  }

  .popupHistoryHeader {
    padding-bottom: 20px;

    div {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }

    span {
      color: var(--primaryColor);
      font-weight: bold;
      font-size: 20px;
      cursor: pointer;
    }

    p {
      color: var(--primaryColor);
      font-weight: 500;
      font-size: 18px;
      width: 90%;
      margin-left: auto !important;
    }
  }

  .historyTableWrapper {
    height: 100%;
    overflow-y: auto;
  }

  body ::-webkit-scrollbar {
    display: none;
  }

  #page-container {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  body > img {
    display: none;
  }

  .avatar-wrap img {
    z-index: 0 !important;
  }

  // .ibanText {
  //   margin-top: -4px !important;
  // }

  #showHistory, #showEarning {
    padding-bottom: 1em !important;
  }

  .applause {
    float: right;
    margin-top: -15px;
    margin-right: 50px;
  }

  .row {
    width: 100%;
    display: flex;
    padding: 16px 24px;
  }

  tbody tr:nth-child(odd) {
    background-color: #F9F9FB;
  }

  .card {
    background: var(--primaryAccent);
    border-radius: var(--borderRadius);
    -webkit-box-shadow: 34px 34px 89px 0 rgb(58 69 129 / 13%);
    box-shadow: 34px 34px 89px 0 rgb(58 69 129 / 13%);
    padding: 16px 24px;
    margin-bottom: 8px;
  }

  .card.card-offer {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
  }

  .card-offer {
    flex-wrap: wrap;
    padding: 0px;
  }

  .tabs .tab-content {
    padding: 46px 0;
  }

  table {
    table-layout: auto;
    border: none !important;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 auto auto;
  }

  thead {
    text-align: center;
  }

  th {
    padding: 15px;
    font-weight: 500;
    color: var(--primaryColor) !important;
  }

  td {
    padding: 8px;
    border: none !important;
    font-size: 12px;
    color: var(--primaryColor);
    font-weight: 500;
  }

  // .popupHistory {
  //   top: 0;
  // }

  .popupHistory .card {
    overflow: auto;
  }

  .sticky-button {
    position: sticky;
    bottom: 20px;
  }

  #userIBAN, #userBIC {
    font-size: 13px;
    color: #4e4e4e;
    font-weight: normal;
    padding-left: 80px !important;
  }

  #userIBAN::placeholder, #userBIC::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--placeholderColor);
    opacity: 1; /* Firefox */
    font-size: 12px;
    font-family: var(--fontFamily);
  }

  #userIBAN:-ms-input-placeholder, #userBIC:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--placeholderColor);
    font-size: 12px;
    font-family: var(--fontFamily);
  }

  #userIBAN::-ms-input-placeholder, #userBIC::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--placeholderColor);
    font-size: 12px;
    font-family: var(--fontFamily);
  }

  .pspBalance {
    background: #fff;
    border-radius: 20px;
    padding: 18px 0;
    text-align: center;
    font-size: 16px;
    margin: 18px 0;
    color: #205072;
  }

  .pspMessage {
    background: #3A4581;
    border-radius: 20px;
    padding: 18px 26px;
    text-align: left;
    font-size: 16px;
    margin: 18px 0;
    color: #fff;

    &.pspError {
      background: #F75010;
    }

    &.pspInformation {
      background: #329D9C;
    }

    a {
      color: #fff;
    }
  }

  .activationPage {
    max-width: 700px;
    margin: 60px auto auto;
    width: 90%;

    input {
      margin-bottom: 20px;
    }

    .successBlock {
      display: flex;
      margin-top: 30px;
      justify-content: center;

      .title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      & > div {
        text-align: left;
        font-size: 15px;
      }

      span {
        font-weight: bold;
        color: $TxtColor1;
      }
    }

    .errorBlock {
      text-align: center;
      margin-top: 30px;
      color: red;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}