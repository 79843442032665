@import 'constant.scss';

.bannerWrapper {
  background-color: $TxtColor1;
  color: $BtnTxtColor1;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;

  &.sav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #234F71;
    height: initial;
    padding: 5px 0;
    z-index: 100;

    a{
      margin-left: initial;
      background-color: initial;
      color: $BtnTxtColor1;
      padding: initial;
    }
  }
 
  a {
    margin-left: 10px;
    background-color: white;
    padding: 5px;
    color: $TxtColor4;
    cursor: pointer;
  }
}

@media only screen and (max-width: 600px) {
  .bannerWrapper {
    flex-direction: column;
    font-size: 1em;
    text-align: center;
    height: 90px;
    justify-content: center;
    div {
      margin-top: 10px;
    }
  }
}
