@import './constant.scss';

.bvnV2 {
    .connexionclient__backgroundBlock {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .connexionclient__mainWrapper {
        min-height: calc(100vh - 310px);
        overflow: auto;


        &.withBackground {
            .connexionclient__mainHeading,
            .stepLabel,
            label,
            .reverseLoginBtn {
                color: #fff;
            }
        }
    }

    .connexionclient__mainHeading {
        color: $ClientColor1;
        text-align: center;
        font-size: 18px;
        font-weight: 900;
        font-family: 'Roboto';
        margin: 39px auto 14px !important;
    }

    hr.connexionclient__horizontalBar {
        border-bottom: 2px solid $ClientColor3;
        width: 90px;
        margin: auto auto 29px auto;
    }

    .connexionclient__stepsContainer {
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: space-around;
        text-align: center;
        font-size: 14px;
        margin: auto 15px 26px 15px;

        &.ticketCo div {
            //background-image: url('assets/ticket_co_pin.png');
            background-image: url('https://ticketcommercant.s3.eu-west-3.amazonaws.com/wp-content/uploads/2020/12/02192827/PATTERNBLANC-1.png');
            background-size: 310px;
            background-position: -83px -40px;
            background-repeat: no-repeat;

            p:first-child {
                margin-bottom: 45px !important;
            }
        }

        p:first-child {
            height: 50px;
            margin-bottom: 15px !important;
            font-size: 50px;
            font-weight: bolder;
            color: $ClientColor1;
            text-align: center;
        }

        p {
            max-width: 150px;
            color: rgba(0, 0, 0, 0.71);
        }
    }

    .connexionclient__loginForm {
        width: 90%;
        margin: auto;
        text-align: center;
        margin-bottom: 32px;

        label {
            font-size: 14px;
            color: $ClientColor1;
            font-weight: 500;
            display: block;
            margin-bottom: 10px;
        }

        .formControl {
            max-width: 222px;
            border-radius: 10px;
            height: 40px;
            text-align: center;
            margin: auto auto 15px auto;
            display: block;
        }

        input {
            font-size: 22px;
            font-weight: 700;
            color: black;
        }

        input::placeholder {
            font-size: 22px;
            font-weight: 700;
        }

        .tel-input {
            width: 220px;
        }

        .validationCode::placeholder {
            font-size: 20px;
        }

        button {
            width: 100%;
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;
        }

        .reverseLoginBtn {
            background: none;
            color: $ClientColor1;
            font-weight: bold;
            cursor: pointer;
            border: none;
            text-decoration: underline;
            font-size: 14px;
            width: 222px;
        }
    }

    .connexionclient__footerWrapper {
        width: 90%;
        margin: auto;
        padding-bottom: 12px;

        p {
            font-size: 10px;
            font-weight: 900;
            color: rgba(0, 0, 0, 0.54);
            padding-bottom: 10px !important;
        }
    }

    @media only screen and (max-width: 450px) {
        .connexionclient__mainHeading {
            margin: 12px auto 11px !important;
        }
        hr.connexionclient__horizontalBar {
            margin-bottom: 29px;
        }
        .connexionclient__stepsContainer {
            font-size: 11.5px;

            p {
                max-width: 100px;
            }

            &.ticketCo div {
                background-size: 270px;
                background-position: -88px -30px;
            }
        }
        .connexionclient__loginForm {
            label {
                font-size: 11.5px;
            }
        }
        .connexionclient__footerWrapper {
            p {
                font-size: 6px;
            }
        }
    }
}