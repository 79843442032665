//does not work with colors containing alpha
@function encodecolor($string) {
  @if type-of($string) == "color" {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote("#{$hex}");
  }
  $string: "%23" + $string;
  @return $string;
}


[type=checkbox], [type=radio] {
  width: 22px;
  height: 22px;
}


:root {
  --pagebg: #f5f8fa;
  --partnerTxtColor1: #3a4581;
  --partnerTxtColor2: #3a4581;
  --partnerTxtColor3: #3a4581;
  --partnerTxtColor4: #3a4581;
  --partnerTxtColor5: #3a4581;
  --partnerTxtColor7: #c7363c;
  --partnerTxtColor8: #3a4581;
  --partnerTxtColor9: #3a4581;
  --partnerBorderColor1: #eba0a4;
  --partnerBtnColor1: #3a4581;
  --partnerBtnTxtColor1: #ffffff;
  --partnerBtnColor2: #ffffff;
  --partnerBtnTxtColor2: #3a4581;
  --partnerBtnColor3: #ffffff;
  --partnerBtnTxtColor3: #3a4581;
  --partnerBtnRed : #c7363c;
  --partnerBtnBorderWidth: 2px;
  --partnerBgColor: #f5f8fa;
  --partnerBgColor2: #f5f8fa;
  --partnerFontFamily: "Roboto", sans-serif;
  --partnerTitleFontFamily: "Roboto", sans-serif;
  --red: #f00;
  --blue: #3a4581;
  --yellow: #ffe801;
  --transparentBlue: #3a4581b0;
  --blue_tmp: #131943;
  --transparentBlue: #3a4581b0;
  --link-blue: #ffffff;
  --white: #ffffff;
  --background-grey: #fff;
  --background-secondary: #c7363c;
  --primaryColor: #3a4581;
  --primaryLightColor: #8f97b8;
  --primaryLighterColor: #e5e7ef;
  --primaryAccent: #ffffff;
  --secondaryColor: #d84148;
  --trademarkColor: #56c596;
  --textColor: #000000;
  --placeholderColor: #939394;
  --fontFamily: "Roboto", sans-serif;
  --basePadding: 16px;
  --baseFontSize: 14px;
  --borderRadius: 21px;
  --borderRadiusSmall: 16px;
  --borderRadiusProduct: 14px;
  --boxShadow1: 8px 8px 13px 0 rgba(0, 0, 0, 0.13);
  --boxShadow2: 13px 13px 34px 0 rgba(0, 0, 0, 0.13);
  --boxShadow3: 21px 21px 55px 0 rgba(0, 0, 0, 0.13);
  --boxShadow4: 34px 34px 89px 0 rgba(58, 69, 129, 0.13);
  --baseBoxShadow : 34px 34px 89px 0 rgba(58, 69, 129, 0.13);
  --btnPadding: 20px;
  --btnPaddingSmall: 16px 12px;
  --validGreen: #4eac5b;
  --validBlue: #4e73be;
  --validRed: #b02318;
  --fontWeightBold : 500;
  --magasinLoadingBackground: #00898f;
}

$pagebg: var(--pagebg);
$TxtColor1: var(--partnerTxtColor1);
$TxtColor2: var(--partnerTxtColor2);
$TxtColor3: var(--partnerTxtColor3);
$TxtColor4 : var(--partnerTxtColor4);
$TxtColor5 : var(--partnerTxtColor5);
$BtnColor1: var(--partnerBtnColor1);
$BtnTxtColor1: var(--partnerBtnTxtColor1);
$BtnColor2: var(--partnerBtnColor2);
$BtnTxtColor2: var(--partnerBtnTxtColor2);
$BtnColor3: var(--partnerBtnColor3);
$BtnTxtColor3: var(--partnerBtnTxtColor3);
$BtnColor4: var(--partnerBtnColor4);
$BtnTxtColor4: var(--partnerBtnTxtColor4);
$BtnColor5: var(--partnerBtnColor5);
$BtnTxtColor5: var(--partnerBtnTxtColor5);
$BtnBorderWidth: var(--partnerBtnBorderWidth);
$BgColor: var(--partnerBgColor);
$BgColor2: var(--partnerBgColor2);
$ClientColor1: var(--clientColor1);
$ClientColor2: var(--clientColor2);
$ClientColor3: var(--clientColor3);
$ClientMainBtnColorBg: var(--clientMainBtnColorBg);
$ClientMainBtnColorText: var(--clientMainBtnColorText);
$ClientSecBtnColorBg: var(--clientSecBtnColorBg);
$ClientSecBtnColorText: var(--clientSecBtnColorText);
$ClientBackgroundColor: var(--clientBackgroundColor);
$red: var(--red);
$blue: var(--blue);
$yellow: var(--yellow);
$blue_tmp: var(--blue_tmp);
$transparentBlue: var(--transparentBlue);
$link-blue: var(--link-blue);
$white: var(--white);
$background-grey: var(--background-grey);
$primaryColor: var(--primaryColor);
$primaryLightColor: var(--primaryLightColor);
$primaryLighterColor: var(--primaryLighterColor);
$primaryAccent: var(--primaryAccent);
$secondaryColor: var(--secondaryColor);
$trademarkColor: var(--trademarkColor);
$textColor: var(--textColor);
$placeholderColor: var(--placeholderColor);
$fontFamily: var(--partnerFontFamily);
$titleFontFamily: var(--partnerTitleFontFamily);
$basePadding: var(--basePadding);
$baseFontSize: var(--baseFontSize);
$borderRadius: var(--borderRadius);
$borderRadiusSmall: var(--borderRadiusSmall);
$borderRadiusProduct: var(--borderRadiusProduct);
$boxShadow1: var(--boxShadow1);
$boxShadow2: var(--boxShadow2);
$boxShadow3: var(--boxShadow3);
$boxShadow4: var(--boxShadow4);
$btnPadding: var(--btnPadding);
$btnPaddingSmall: var(--btnPaddingSmall);
$validGreen: var(--validGreen);
$validBlue: var(--validBlue);
$validRed: var(--validRed);
$fontWeightBold: var(--fontWeightBold);
$baseBoxShadow : var(--baseBoxShadow);
$background-secondary : var(--background-secondary);
$BtnRed : var(--partnerBtnRed);
$partnerBorderColor1 : var(--partnerBorderColor1);