@import 'src/style/constant.scss';

.Refund {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 26px;

  .sellerWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 30px;
    width: 350px;
    min-height: 150px;
    border-radius: 20px;
    overflow: hidden;
    font-weight: bold;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

    .sellerInfo {
      width: 100%;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }

    .sellerName {
      background: var(--clientMainBtnColorBg);
      color: var(--clientMainBtnColorText);
      font-weight: 500;
      font-size: 16px;
      text-align: center;
    }

    .sellerAddress {
      gap: 10px;
      text-align: center;

      .sellerAddressIcon {
        display: flex;
        align-items: center;
        color: var(--clientMainBtnColorText) !important;
      }
    }

    .sellerShortcode {
      position: relative;
      text-align: center;

      .sellerShortcodeHelperMark {

        &:hover {
          .sellerShortcodeHelper {
            display: block;
          }
        }

        .sellerShortcodeHelper {
          position: absolute;
          display: none;
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
          background: var(--clientMainBtnColorBg);
          color: var(--clientMainBtnColorText);
          padding: 2px 4px;
          border-radius: 4px;
          font-size: 12px;
          width: 55%;
          text-align: center;
        }
      }
    }
  }

  .title {
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    color: $ClientColor1;
    margin-top: 30px;
  }

  .subTitle {
    font-size: 16px;
    max-width: 320px;
    text-align: center;
    margin-top: 10px;
  }

  svg {
    stroke: $ClientColor1;
  }

  .bigText {
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    color: $ClientColor1;
    text-align: center;
  }

  &.Ask {
    .userInfos {
      text-align: center;
      max-width: 350px;
      width: 95%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 15px;
      margin-top: 30px;

      .field {
        min-height: 30px;
        display: flex;
        border-radius: 50px;
        border: 1px solid var(--clientColor1);
        color: var(--clientColor3);
        font-size: 14px;
        width: 100%;
        overflow: hidden;
        position: relative;
        align-items: center;
        justify-content: center;

        &:focus, &:focus-within {
          outline: 2px solid var(--clientColor1) !important;
        }

        .subField {
          width: 100%;
          min-height: 30px;
          padding: 0 25px 0 100px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .subFieldIcon {
          position: absolute;
          left: 20px;
          top: 7.5px;
          font-size: 15px !important;
          text-transform: capitalize;
        }
      }
    }

    .list {
      font-size: 15px;
      margin-top: 30px;

      div {
        display: flex;
      }

      span {
        display: inline-flex;
        width: 24px;
        height: 24px;
        background: $ClientMainBtnColorBg;
        border-radius: 24px;
        align-items: center;
        justify-content: center;
        color: $ClientMainBtnColorText;
        font-size: 16px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }

    .inputBlock {
      margin-top: 30px;
    }

    .or {
      font-size: 16px;
      margin: 10px 0;
    }

    .takePhoto {
      background: $ClientMainBtnColorBg;
      border: 1px solid $ClientMainBtnColorBg;
      border-radius: 50px;
      padding: 10px 30px;
      color: $ClientMainBtnColorText;
      font-weight: 500;
      font-size: 16px;
    }

    .pictureOverlayer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      background: #0000006e;
      z-index: 1;
      align-items: center;
      justify-content: center;

      button {
        position: absolute;
        border-radius: 40px;
        border: none;
        background: #ffffffb5;
      }

      .close {
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        font-size: 30px;
      }

      .snap {
        bottom: 50px;
        left: 0;
        right: 0;
        margin: auto;
        width: 80px;
        height: 80px;
      }

      .cancel, .valid {
        bottom: 50px;
        margin: auto;
        width: 80px;
        height: 80px;
        font-size: 40px;
      }

      .cancel {
        left: 60px
      }

      .valid {
        right: 60px;
      }
    }

    .validationLayer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: $ClientColor2;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $ClientColor1;
      font-weight: 400;
      font-size: 16px;

      .buttons {
        margin: 20px 0;
        display: flex;
        justify-content: space-evenly;
        align-self: stretch;
      }
    }
  }

  &.Confirmation {
    .image {
      margin-top: 30px;
    }
    .bigText {
      margin-top: 20px;
    }
    .moreInfoButton {
      background: none;
      border: none;
      margin: 20px 0 60px;

      .text {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        text-decoration: underline;
        color: $ClientColor1;
      }
    }

    .mainButton, .secButton {
      margin-top: 10px;
      text-align: center;
      width: 250px;
    }

    .popupBase {
      text-align: center;
      .title {
        font-weight: 900;
        font-size: 23px;
      }
      .subTitle {
        font-size: 14px;
        font-weight: bold;
        color: $ClientColor1;
      }
      .popupBlock {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        margin: 30px 20px 10px;

        &.small {
          font-size: 12px;
          opacity: 0.75;
          line-height: 13px;
        }

        a{
          color: $ClientColor1;
          text-decoration: underline;
        }
      }
    }
  }

  &.List {
    .title {
      margin-bottom: 20px;
    }

    .refund {
      border: 2px solid;
      max-width: 390px;
      width: 100%;
      margin: 5px 0;
      border-radius: 20px;
      position: relative;
      overflow: hidden;
      display: flex;
      padding: 10px 0;

      .label {
        position: relative;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        margin: -10px 0;
        
        span {
          display: block;
          transform: rotate(-90deg);
          white-space: nowrap;
        }
      }

      .info {
        font-size: 15px;
        margin: 2px 10px;
        span {
          font-weight: bold;
        }
      }

      &.blue {
        border-color: #108D94;
        .label {
          background: #108D94;
        }
      }
      &.red {
        border-color: #DE2424;
        .label {
          background: #DE2424;
        }
      }
      &.green {
        border-color: #9AC93B;
        .label {
          background: #9AC93B;
        }
      }
    }
  }
}