@font-face {
  font-family: Rubik;
  src: url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
}
body {
  // height: initial !important;
}

#root {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  //padding-bottom: 50px;
}

.bvnV2, .adminBvn {

  .button {
    border: $BtnBorderWidth solid $BtnColor1;
    padding: 20px 0;
    border-radius: 21px;
    width: 100%;
    display: block;
    text-align: center;
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;

    &.disabled {
      filter: grayscale(1) brightness(0.5);
    }
  }

  h1, h2, h3, h4, h5, h6 {
    color: #205072;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    padding-bottom: 10px !important;
    line-height: 1em !important;
    margin: 0 !important;
    font-family: 'Roboto' !important;
  }

  h3 {
    font-size: 22px !important;
  }

  h5 {
    font-size: 16px !important;
  }


  .button1 {
    color: $BtnTxtColor1;
    background: $BtnColor1;
  }

  .button2 {
    color: $BtnTxtColor2;
    background: $BtnColor2;
  }

  .button3 {
    color: $BtnTxtColor3;
    background: $BtnColor3;
  }

  .button4 {
    color: $BtnTxtColor4;
    background: $BtnColor4;
  }

  .button5 {
    color: $BtnTxtColor5;
    background: $BtnColor5;
  }

  a {
    text-decoration: none;
    color: $link-blue;
  }

  .d-none {
    display: none;
  }

  .d-block {
    display: block;
  }

  .error {
    color: $red;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 0px;
  }

  .infoPicto {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 50%, transparent 50%);
    background-color: $ClientColor1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: "Droid Serif";

    span {
      font-size: 0.9em;
    }
  }

  .LogoBlock {
    text-align: center;
    padding: 0px;
    margin: 0 auto;

    &.big {
      max-width: 300px;
    }

    &.tico {
      max-width: 300px;
      min-width: 150px;
      margin-left: 20px;
    }

    a {
      display: block;
      width: 300px;
      height: 112px;
      margin: auto;
    }

    img {
      object-fit: contain;
    }


    @media only screen and (max-width: 540px) {
      a {
        width: 250px;
        height: 80px;
      }
    }
    @media only screen and (max-width: 470px) {
      a {
        width: 180px;
      }
    }
    @media only screen and (max-width: 370px) {
      a {
        width: 165px;
      }
    }
    @media only screen and (max-width: 340px) {
      a {
        width: 150px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    input {
      font-size: 16px !important;
    }
  }

  .mainButton, .secButton {
    width: 150px;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 50px;
    background: transparent;
    padding: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  .mainButton {
    border-color: $ClientMainBtnColorBg;
    background-color: $ClientMainBtnColorBg;
    color: $ClientMainBtnColorText;
  }

  .secButton {
    border-color: $ClientSecBtnColorText;
    background-color: $ClientSecBtnColorBg;
    color: $ClientSecBtnColorText;
  }

  .roundCheckbox {
    border-radius: 50px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;

    .check {
      background: transparent;
      width: 50%;
      height: 50%;
      border-radius: 50px;

      &.checked {
        background: black;
      }
    }
  }
}