@import "constant.scss";

.bvnV2 {
  .login {
    display: flex;
    min-height: 800px;
  }

  .formBody {
    width: 350px;
    margin: auto;
  }

  .formBody div {
    margin-top: 10px;
  }

  .loginTitle {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.17;
    color: $TxtColor1;
    margin-bottom: 20px;
    font-family: $titleFontFamily;
  }

  .formBody div label {
    width: 100%;
    font-weight: 400;
    padding: 0;
    margin-bottom: 5px;
    padding-left: 15px;
    display: block;
    color: $TxtColor1;
  }

  .required {
    color: $red;
  }

  .intl-tel-input .country-list {
    max-height: 210px;
  }

  .formBody div input {
    display: block;
    border: none;
    -webkit-box-shadow: 34px 34px 89px 0 rgba(58, 69, 129, 0.13);
    box-shadow: 34px 34px 89px 0 rgba(58, 69, 129, 0.13);
    outline: none;
    height: 55px;
    line-height: 55px;
    padding: 0 34px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 100%;
    border-radius: 21px;
    box-sizing: border-box;
  }

  .loginImageDesktop {
    display: block;
    margin: 20px;
    max-width: 643px;
    max-height: 701px;
  }
.loginImageDesktop img{
  border-radius: 10px;
}
  .loginImageMobile {
    display: none;
  }

  .login.ticketCommercantLogin {
    flex-direction: column;

    .formBody {
      margin: 20px auto 150px;
    }

    .loginTitle, label {
      color: white;
    }

    .background {
      width: 100%;
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      padding-top: 20px;
    }
  }

  @media (max-width: 768px) {
    .formBody {
      width: 80%;
    }

    .loginImageDesktop {
      display: none;
    }
    .loginImageMobile {
      display: block;
    }
    .loginImageMobile img {
      border-radius: 20px;
    }
  }
}