@import './constant.scss';

.bvnV2 {
  .popupImageHeader {
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: auto auto;
  }

  .partnerPopupImageHeader {
    grid-template-columns: auto 155px auto !important;
  }

  .popupImage {
    height: 53px;
  }

  .popupCloseButtonWrapper {
    margin-left: auto;
    margin-top: -10px;
  }

  .popupBodyContentWrapper {
    display: grid;
    grid-template-columns: 53% 40%;
    justify-content: space-between;
    text-align: center;
    margin-top: 10px;
  }

  .stcLinkBtn {
    border-color: rgba(58, 69, 129, 0.08) !important;
    padding: 10px !important;
    font-size: 12px !important;
  }

  .qrCodeReader {
    background-color: black !important;
    padding: 0 !important;
    height: 100%;
  }

  .qrCamera {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 145px);
  }

  .qrValidateContainer {
    width: inherit;
    background: $secondaryColor;
    position: fixed;
    bottom: 0;
  }

  .qrValidateSubContainer {
    margin: 19px 26px;
  }

  .qrValidateWrapper {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    margin-top: 25px;
  }

  .qrValidateTxt {
    font-size: 16px;
    margin-left: 10px !important;
    color: $white;
  }

  .qrValiderBtn {
    font-size: 14px;
    border: solid 2px $partnerBorderColor1 !important;
    font-weight: 400;
    color: $white;
    width: 97px !important;
  }

  .qrCodeWrapper {
    display: grid;
    grid-template-columns: auto 30px;
    align-items: center;
    background-color: $secondaryColor;
  }

  .qrHeading {
    color: $white;
    margin-top: 15px !important;
    text-align: center;
  }

  .qrScannerBtnWrapper {
    text-align: center;
  }

  .cross-icon {
    width: 35px !important;
    height: 35px !important;
    background-color: $primaryColor;
    border-radius: 20px;
    text-align: center;
    color: $white;
    line-height: 2.3;
    font-family: cursive;
    background-size: contain;
    margin: 1px;
  }

  .qrScannerBtn {
    border-color: rgba(58, 69, 129, 0.08) !important;
    width: 90% !important;
    margin-top: 20px;
    margin-bottom: 25px;
    font-size: $baseFontSize !important;
    font-weight: $fontWeightBold !important;
    background-color: $primaryColor !important;
    color: $white !important;
  }

  #qrScanner section section div:first-child {
    height: 75% !important;
  }

  #qrScanner section section video {
    height: 75% !important;
  }

  .clearBtn {
    background: $BtnRed !important;
    width: 97px !important;
    height: 56px;
    padding: 0 !important;
  }

  .popupSeparatorLine {
    height: 1px;
    color: #e6eaf0;
    background-color: #e6eaf0;
    margin-left: -50px;
    margin-right: -30px;
  }

  .popupFooterWrapper {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 42% 42%;
    font-size: 12px;
    text-align: center;
    justify-content: space-between;
  }

  .popupFooterText {
    color: $primaryColor;
    font-weight: 400;
  }

  .popupFooterBtn {
    padding: 5px 10px !important;
    margin-top: 15px;
    background-color: #e6eaf0 !important;
    font-weight: bold !important;
    font-size: 12px !important;
    border-radius: 8px !important;
    width: 109px !important;
    border: none !important;
  }

  .usedBudgetImgContainer {
    text-align: center;
  }

  .usedBudgetContent {
    margin-top: 39px;
  }

  h3.usedBudgetPopupHeading {
    font-size: 20px !important;
  }

  .usedBudgetPopupTxt {
    color: $primaryColor;
    font-size: $baseFontSize;
  }

  .sendEmailBudgetPopup {
    width: 100%;
    margin-top: 26px;
    font-size: $baseFontSize;
    height: 60px;
    border-radius: $borderRadius;
    border: none;
    background: $primaryColor;
    color: $white;
    cursor: pointer;
  }

  .cancelBudgetPopup {
    width: 100%;
    margin-top: 16px;
    font-size: $baseFontSize;
    height: 60px;
    border-radius: $borderRadius;
    border: 2px solid $primaryColor;
    color: $primaryColor;
    background: $white;
    font-weight: $fontWeightBold;
    font-family: $fontFamily;
  }

  a.sendEmailBtn {
    color: $white !important;
  }

  .avantagesCard {
    align-items: center;
    display: grid;
    grid-template-columns: 50px auto auto;
  }

  #popupCancel,
  #commerceBox,
  #partnerErrorPopup,
  #usedBudgetPopup {
    position: fixed;
    background: $white;
    z-index: 999999;
    bottom: 0;
    padding: 30px;
    border-radius: 16px 16px 0 0;
    -webkit-box-shadow: var(--boxShadow3);
    box-shadow: var(--boxShadow3);
    width: 100%;
    max-width: 850px;
    right: 0;
    left: 0;
    margin: auto;
  }

  .content-wrap-modifier {
    position: absolute;
    left: 27%;

  }

  .create-btn {
    position: fixed;
    width: 100%;
    max-width: 818px;
    bottom: 20px;
  }

  .times-cust {
    i {
      border: 2px solid red;
      border-radius: 50%;
      padding: 2px 3px;
      color: red;
      font-size: 12px;
    }
  }

  @media only screen and (min-width: 850px) {
    .content-wrap-modifier {
      position: absolute;
      left: 30%;
    }
  }
  @media only screen and (max-width: 850px) {
    .create-btn {
      max-width: 95%;
    }
  }

  @media only screen and (max-width: 550px) {
    .create-btn {
      max-width: 92%;
    }
  }


  @media only screen and (min-width: 444px) {
    .popupFooterWrapper {
      grid-template-columns: 50% 42%;
    }
  }


  @media only screen and (max-width: 334px) {
    .popupFooterWrapper {
      grid-template-columns: 50% 42%;
    }
  }
}