@import 'constant.scss';

.adminPage {
  min-height: 800px;
  background: $BgColor;
  padding: 20px 0;

  & ::-webkit-scrollbar {
    display: initial;
  }

  #message {
    text-align:center;
    color:green;
    font-weight: bold;
    font-size: 26px;
  }

  .action_list {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 20px;
      border-radius: 5px;
      padding: 10px;
      cursor: pointer;
    }
  }
  input{
    cursor: pointer;
  }
  .title {
    font-size: 16px;
    margin: auto;
    text-align: center;
    color: $TxtColor2;
  }

  div .adminSearch {
    display: block;
    border: 1px solid rgba(58, 69, 129, 0.13);
    -webkit-box-shadow: 34px 34px 89px 0 rgba(58, 69, 129, 0.13);
    box-shadow: 34px 34px 89px 0 rgba(58, 69, 129, 0.13);
    outline: none;
    height: 55px;
    line-height: 55px;
    padding: 0 14px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border-radius: 21px;
    box-sizing: border-box;
    width: 400px;
    max-width: 80%;
    margin: 15px auto;
  }

  .statusChangeMessage{
    text-align: center;
    font-size: 30px;
    color: $white;
  }
  .tabulator {
    margin: auto;
    width: 1434px;
    max-width: 100%;
    border-color: $BgColor;
    border-width: 2px;

    .tabulator-header .tabulator-col {
      background: $BgColor;
      color: $TxtColor3;
    }

    .tabulator-header,
    .tabulator-col.tabulator-sortable,
    .tabulator-row,
    .tabulator-cell {
      border-color: $BgColor;
      border-width: 2px;
      text-transform: uppercase;
    }

    .select {
      padding: 0;

      .formatterCell {
        height: 100%;
      }

      select {
        height: 100%;
        border: none;
        width: 100%;
        background: none;
      }
    }

    .selectable {
      color: $link-blue;
      font-weight: bold;
    }

    .accepted, .ok {
      color: $validGreen;
    }
    .pending, .processing {
      color: $validBlue;
    }
    .denied, .blocked, .banned {
      color: $validRed;
    }
    .contract_signature_icon {
      color: $TxtColor2;
    }
  }
}

@media (max-width: 768px) {
}
