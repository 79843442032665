 /* Popup */
 .bvnV2 {
   body.has-popup, .ticketsInfoPopupActive.has-popup {
     height: 100vh !important;
   }

   body.has-popup:after, .ticketsInfoPopupActive.has-popup:after {
     content: "";
     width: 100%;
     height: 100%;
     background-color: rgba(58, 69, 129, 0.2);
     display: block;
     position: fixed;
     top: 0;
     left: 0;
     z-index: 99999;
     height: 100vh !important;
   }

   #popup {
     position: fixed;
     background: white;
     width: 90%;
     z-index: 999999;
     top: 50%;
     left: 50%;
     -webkit-transform: translate(-50%, -50%);
     -ms-transform: translate(-50%, -50%);
     transform: translate(-50%, -50%);
     padding: 30px;
     border-radius: 16px;
     max-height: 95vh;
     -webkit-box-shadow: var(--boxShadow3);
     box-shadow: var(--boxShadow3);
   }

   #popupContent {
     overflow-y: auto;
     max-height: 80vh;
   }

   .btn-primary.btn-flat {
     color: white !important;
   }

   #popup .close {
     right: 15px;
     top: 10px;
     position: absolute;
     background: none;
     border: none;
     font-size: 20px;
     color: var(--primaryColor);
     font-weight: bold;
     cursor: pointer;
   }
 }