@import 'constant.scss';

.bvnV2 {
  :root {
    --content_width: 60%;
  }


.myprovence_container{
  position:relative;
}
.myprovence_wrapper{
  position:absolute;
  bottom:10%;
  left:0;
  right:0;
}
  .framed_text {
    border: 2px solid #ffe801;
  color: $blue_tmp !important;
    font-size: 28px;
    padding: 35px 27px 27px;
    margin-top: 16px;
  }

  .framed_text span {
  color: #ffe801 !important;
    font-weight: bold;
    font-size: 30px;
  }

  .box {
    margin: 20px 0;
  }

  .box_title {
  color: $blue_tmp !important;
    font-size: 32px;
  }

  .box_text {
    width: 38vw;
    color: $white;
    border: 2px solid #ffe801;
    font-size: 24px;
    border-radius: 5px;
    padding: 10px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;

    background: rgba(16, 16, 17, 0.5);
  }

  .box_text > a {
  color: $blue_tmp !important;
    text-decoration: underline;
  }

  .tmp_header {
    background-color: #ffffff;
  }

  .tmp_container {
    margin: 0;
    box-sizing: border-box;
    opacity: 1;
  color: $blue_tmp !important;
  background: #ffffff !important;
  }

  .tmp_wrapper {
    width: var(--content_width);
    margin: 0 auto;
    padding-bottom: 25px;
    padding-top: 30px;
  }

  .tmp_welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
    color: $blue_tmp !important;
      font-size: 48px;
      text-align: center;
      width: 100%;
      font-family: 'MostraOne' !important;
    }

    div {
      text-align: center;
    }

    .tmp_welcome_text {
      font-size: 32px;
    color: $blue_tmp !important;
      margin: 70px 0;
    }
  }

  .tmp_boxes > p {
  color: $blue_tmp !important;
    font-weight: bold;
    font-size: 32px;
    font-family: 'MostraOne';
  }

  .date {
  color: $blue_tmp !important;
    font-size: 32px;
    font-weight: bold;
  }

  footer {
    height: 106px;
    background-color: $white;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .tmp_wrapper {
      width: 70%;
    }

    .framed_text {
      font-size: medium;
      padding: 10px 10px 10px 10px;
    }

    .framed_text > p {
      line-height: 1.5em !important;
    }

    .framed_text > p > span {
      font-size: medium;
    }

    .box_text {
      width: 60vw;
    }

    .box > p {
      font-size: medium;
    }

    .tmp_welcome h1 {
      font-size: large;
    }

    .tmp_welcome_text {
      font-size: medium;
    }
    .date {
      font-size: medium;
    }

    .tmp_boxes > p {
      font-size: medium;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 990px) {
    .box_text {
      width: 65vw;
    }

    .tmp_boxes > p {
      font-size: medium;
    }
  }
}