.ticketExchangeWrapper{
    text-align: center;
    p.ticketExchangeHeading{
        margin: 26px auto !important;
        font-weight: 900;
        font-size: 32px;
    }
    .terminationContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    p.terminationHeading{
        margin: 0 !important;
        padding: 32px 0 18px 0 !important;
        font-weight: 900;
        font-size: 32px;
        color: #FFFFFF;
    }
    .terminationTxt{
        font-weight: 900;
        font-size: 18px;
        color: #FFFFFF;
        text-align: center;
        max-width: 289px;
        margin: 22px auto 30px auto !important;
    }
    .terminationBtn{
        max-width: 150px;
        width: 100%;
        min-height: 42px;
        border-radius: 50px;
        margin-bottom: 32px;
        background: #FFFFFF;
        border: none;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
    }
}