@import "constant.scss";
@import "common.scss";

.DateRangePicker {

  .InputDatePicker {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    text-align: center;
    border-radius: 10px;
    height: 40px;
    gap: 16px;
    color: #000;
    color: var(--partnerTxtColor1);
    background-color: #fff;

    &:focus-within {
      outline: 2px solid;
    }
  }

  .react-datepicker__input-container {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .date-picker-input {
    color: #000;
    color: var(--partnerTxtColor1);
    padding: 2px 4px;
    border-radius: 5px;
    outline: none !important;
  }

  .react-datepicker__triangle {
    left: -20px !important;
  }

  .react-datepicker-popper {
    inset: 0 auto auto 428px !important;
  }

  .presets {
    box-sizing: content-box;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 99%;
    left: -1px;
    width: calc(100% + 0.5px);
    gap: 11px;
    border: 1px solid #aeaeae;
    border-radius: 0 0 0.3rem 0.3rem;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;

    .preset {
      background: #fff;
      border: none;
      padding: 4px;
      height: 30px;
      width: 100%;
    }

    .current {
      background: #2579ba;
      border-radius: 0.3rem;
      color: #fff;

      &:hover {
        background: #1d5d90;
      }
    }
  }
}