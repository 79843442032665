@import "./constant.scss";
@import "./popup.scss";

.bvnV2 {
  .titleHeader {
    font-size: 20px;
    font-weight: 900;
    color: var(--partnerTxtColor1);
    margin: 0;
    line-height: 1.4;
    padding: 0;
  }

  .titleChild {
    font-size: 20px;
    font-weight: 300;
    color: var(--partnerTxtColor3);
    margin: 0;
    padding-bottom: 1em !important;
  }

  .custom-select {
    width: 100%;
    background: white;
    border-radius: 14px;
  }

  .custom-select-active {
    border: 1px solid var(--primaryColor);
  }

  .customInputField {
    max-width: 56px;
    height: 40px;
    text-align: center;
    background: white;
    border-radius: 12px;
    border: none !important;
    font-family: "Roboto";
    font-size: 15px;
    font-weight: 500;

    &.large {
      max-width: initial;
      width: 100%;
      text-align: left;
      padding-left: 15px;
    }
  }

  #productDescription:focus,
  #discountAmount:focus,
  #thresholdValue:focus {
    border: 1px solid var(--primaryColor) !important;
    outline: none;
  }

  #discountAmount {
    color: var(--primaryColor);
    font-size: 15px;
    font-weight: 500;
    margin-left: 37px;
  }

  #thresholdValue {
    color: var(--primaryColor);
    font-size: 15px;
    font-weight: 500;
    margin-left: 22px;
  }

  .sticky-bottom {
    margin: 60px 0;
  }

  .validate-error {
    border: 1px solid red !important;
  }

  #productDescription {
    font-size: 15px;
    font-weight: 500;
  }

  .form-group.form-inline.nopad label {
    font-weight: 500;
    font-size: 18px;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--placeholderColor);
    opacity: 1; /* Firefox */
    font-size: 15px;
    font-weight: 500;
    font-family: var(--fontFamily);
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--placeholderColor);
    font-size: 15px;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--placeholderColor);
    font-size: 15px;
  }

  //   React Semantic UI
  .custom-select .ui.selection.dropdown {
    border: none;
    border-radius: 14px;
    padding: 15px;
    width: 100%;
    box-shadow: 34px 34px 89px 0 rgba(58, 69, 129, 0.13) !important;
  }

  .custom-select .ui.active.selection.dropdown {
    border-bottom-left-radius: 14px !important;
    border-bottom-right-radius: 14px !important;
    border: 1px solid var(--primaryColor);
  }

  .custom-select .ui.active.selection.dropdown:hover {
    border: 1px solid var(--primaryColor) !important;
  }

  .ui.default.dropdown:not(.button) > .text,
  .ui.dropdown:not(.button) > .default.text {
    font-size: 15px;
    font-weight: 500;
    color: var(--primaryColor) !important;
  }

  .ui.dropdown > .text {
    font-size: 15px;
    font-weight: 500;
  }

  .visible.menu.transition {
    position: absolute !important;
    top: 60px !important;
    border: none !important;
    border-radius: 14px !important;
    padding: 15px !important;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .visible.menu.transition::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .visible.menu.transition {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .ui.selection.dropdown .menu {
    max-height: unset !important;
  }

  .ui.selection.active.dropdown .menu {
    border: none !important;
    box-shadow: 34px 34px 89px 0 rgba(58, 69, 129, 0.13) !important;
  }

  .visible.menu.transition .item {
    background: transparent !important;
    border: none !important;
    font-size: 15px !important;
    color: var(--primaryColor) !important;
    font-weight: 500 !important;
    margin-bottom: 12px !important;
  }

  .visible.menu.transition .item img {
    width: 20px !important;
    height: 20px !important;
    margin-top: -3px !important;
  }

  .divider.text {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: var(--primaryColor) !important;
  }

  .divider.text img {
    width: 20px !important;
    height: 20px !important;
    margin-top: -3px !important;
  }

  .dropdown.icon {
    font-weight: bolder !important;
    font-size: 15px !important;
    padding-right: 25px !important;
  }

  .productBox {
    // width: 100%;
    padding: 16px;
    padding-bottom: 50px;
  }

  .subHeading {
    padding-bottom: 0px !important;
    margin-top: 6px !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
}