.bvnV2 {
  .languageSelector {
    z-index: 1;

    .toggle {
      color: #929292;
      border: solid 1px #929292;
      border-radius: 5px;
      cursor: pointer;
      padding: 2px 7px;
      font-weight: normal;
      background: none;
      text-transform: uppercase;
      background: white;
    }

    .list {
      position: absolute;
      right: 0;
      display: flex;
      flex-direction: column;
      color: #929292;
      border: solid 1px #929292;
      border-radius: 5px;
      padding: 2px 7px;
      background: white;

      .choice {
        padding: 4px 0;
        border: none;
        background: none;
        cursor: pointer;
        border-top: solid 1px #929292;

        &:first-of-type {
          border-top: none;
        }
      }
    }
  }

  .landingheader .languageSelector {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .client-menu .languageSelector {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;

    .list {
      bottom: 25px;
      right: initial;
    }
  }
}