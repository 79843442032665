@import './constant.scss';
@import './common.scss';

.landingTicket {
  box-sizing: border-box;

  .launchDateText{
    color: white;
    font-size: 25px;
    text-align: center;
  }

  .userType {
    color: $white;
    font-weight: bold;
    margin-bottom: 60px;
    font-family: Draft, sans-serif;
    line-height: 70px;
    text-align: center;
    font-size: 48px;
  }

  .btn_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .signup_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    background-color: $BtnColor1;
    margin-bottom: 20px;
    width: 268px;
    height: 56px;

    &:hover {
      background-color: $BtnTxtColor1;
      .signup {
        color: $BtnColor1;
      }
    }
  }

  .signup {
    border-radius: var(--borderRadius);
    color: $BtnTxtColor1;
    font-weight: bold;
    font-size: 26px;
    font-family: Nunito Roboto, sans-serif;
  }

  .login_link {
    font-weight: bold;
    text-decoration: underline;
    color: $BtnTxtColor1;

    &:hover {
      text-decoration: none;
    }
  }

  .searchBar {
    background: $BtnColor1;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    border: none;
    border-radius: 0;
    margin: 0 0 20px;
    padding: 0;

    input {
      background: white;
      height: 30px;
      flex: 1;
      min-width: 200px;
      width: auto;
      text-align: left;
      padding: 0 15px 0 10px;
      border: solid 2px $BtnColor1;
      border-radius: 30px 0 0 30px;
    }
    .searchText {
      color: $BtnTxtColor1;
      font-size: 18px;
      font-weight: bold;
      background: $BtnColor1;
      height: 100%;
      border-radius: 0 30px 30px 0;
      margin: 0;
      padding: 5px 30px;
      cursor: pointer;
    }
    .searchIcon {
      display: none;
      background: $BtnColor1;
      height: 30px;
      width: 30px;
      border-radius: 30px;
      margin: 0 0 0 -15px;
      padding: 7px;
      cursor: pointer;

      img {
        height: 100%;
      }
    }
  }

  .mapBlock {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 100px 10%;

    .categoryFilters {
      flex: 2;
      margin-right: 20px;
      align-self: flex-start;
      background: white;
      border-radius: 8px;
      box-shadow: 0 0 10px #CFCFCF;
      padding: 30px;
      max-width: 280px;

      .header{
        padding:  20px 20px;
        text-align: center;
        color: $BtnColor1;
        border-bottom: solid 2px #F8F8F8;
        font-size: 16px;
      }
    }
    .categButton {
      display: flex;
      justify-content: space-between;
      margin: 14px 10px;
      align-items: center;
      cursor: pointer;

      .pin {
        width: 30px;
      }

      .label {
        flex: 1;
        margin: 0 10px;
        text-align: center;
        color: #565656;
        font-weight: 600;
        font-size: 15px;
      }
    }
    .map {
      position: relative;
      flex: 1;

      .PinPopup {
        background: white;
        border-radius: 8px;
        box-shadow: 0 0 10px #CFCFCF;
        align-self: flex-start;
        padding: 20px 0;
        width: 200px;
        position: absolute;
        bottom: 10px;
        right: -110px;

        .info {
          padding: 10px;
          padding-bottom: 0;
          font-size: 17px;
          color: $BtnTxtColor1;
          font-weight: bold;
          text-align: center;
          border-top: solid 5px white;
        }
      }

      .moreSellers {
        cursor: pointer;
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
        background: var(--partnerBtnColor1);
        color: var(--partnerBtnTxtColor1);
        padding: 4px 20px;
        border-radius: 30px;
      }
    }
  }

  .tabulator {
    margin: 10px 10%;
    width: 80%;

    .tabulator-headers {
      color: $BtnColor1;
    }

    .tabulator-cell {
      font-size: 13px;
      text-transform: uppercase;
    }
    input {
      border: solid 1px rgb(118, 118, 118);
    }
  }

  &.myprovence {
    .signup_btn {
      background-color: $ClientMainBtnColorBg;
      &:hover {
        background-color: $ClientMainBtnColorText;
        .signup {
          color: $ClientMainBtnColorBg;
        }
      }
    }
    .signup {
      color: var(--textColor);
      font-family: 'MostraOne';
    }
    .userType {
      font-family: 'MostraOne';
    }
    .login_link {
      color: $ClientMainBtnColorBg;
    }

    .map {
      .PinPopup {
        .info {
          color: $ClientMainBtnColorText;
        }
      }
    }
  }

  &.ticketpei {
    .userType {
      font-family: Chaloops, sans-serif;
    }

    .signup_btn {
      background-color: $BtnColor2;

      &:hover {
        background-color: $BtnTxtColor2;
        .signup {
          color: $BtnColor2;
        }
      }
    }

    .signup {
      color: $BtnTxtColor2;
    }

    .login_link {
      color: $BtnTxtColor2;
    }
  }

  .background {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: calc(100vh - 200px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;

    &.solo {
      height: 400px;
      min-height: 400px;
    }

    .backgroundImg {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .backgroundBlock {
      z-index: 1;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
  }

  /* Extra small devices (phones, 992px and down) */
  @media only screen and (max-width: 992px) {
    .background.solo {
      height: initial;
    }
    .navbar_bigScreen {
      display: none;
    }

    .landingTicket_header {
      border-bottom: 2px solid $textColor;
    }

    .hbg-icon {
      font-size: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .contentWrapper {
      display: flex;
      flex-direction: column;
    }
    .background .backgroundBlock {
      height: 50vh;
    }

    .btn_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .userType {
      font-size: 34px;
      margin-bottom: 20px;
      line-height: 50px;
    }

    .login_link {
      font-size: 18px;
    }
  }

  /* Large devices (laptops/desktops, 600px and down) */
  @media only screen and (max-width: 600px) {
    .categoryFilters {
      display: none;
    }
    .searchBar {
      input {
        min-width: 0;
      }

      .searchText {
        display: none;
      }
      .searchIcon {
        display: initial;
      }
    }

    &.ticketpei {
      .searchBar {
        input {
          min-width: 0;
          border-radius: 30px;
        }
      }
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {

    .hbg-icon {
      display: none;
    }
    .contentWrapper {
      display: flex;
    }

    .background {
      flex: 1;
      background-position: right;
    }

    .login_link {
      font-size: 24px;
    }
  }

  .landingIntermarche {
    .desktop {
      display: initial;
    }

    .mobile {
      display: none;
    }

    .header {
      height: 75px;
      width: 100%;
      background: white;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .logo {
        margin: 10px;
        max-width: calc(50% - 20px);
        height: 90%;
        position: relative;

        .LogoBlock {
          height: 100%;
          width: 100%;
          position: relative;

          a {
            height: 100%;
            width: 100%;
          }
        }
      }

      .logo2 {
        max-height: 70%;
        margin-left: 30px;
        max-width: calc(50% - 20px);
        height: auto;
      }
    }

    .contentWrapper {
      background: url('./assets/intermarche_landing_bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      overflow: auto;
      height: initial;
      padding-bottom: 160px;

      .textBlock {
        right: 0;
        width: 40%;
        position: relative;
        display: block;
        margin: 80px 10px auto auto;
        color: white;
        font-family: Roboto;
        font-weight: 400;
        height: initial;

        .title {
          font-size: 46px;
          font-weight: 700;
          line-height: 53px;
          text-transform: uppercase;
          margin-bottom: 18px;
          color: black;

          span {
            font-size: 64px;
          }
        }

        .subTitle {
          font-size: 36px;
          line-height: 42px;
        }

        .paragraph {
          font-size: 22px;
          line-height: 25px;

          .sep {
            height: 20px;
          }
        }
      }

      a {
        position: absolute;
        text-transform: uppercase;
        left: 0;
        right: 10%;
        margin: 0 auto;
        background: black;
        border: none;
        border-radius: 15px;
        display: block;
        text-align: center;
        width: 375px;
        color: white;
        font-size: 24px;
        padding: 12px;
        line-height: 30px;
      }
    }

    @media only screen and (max-width: 1024px) {
      .desktop {
        display: none;
      }
      .mobile {
        display: initial;
      }

      .contentWrapper {
        background-image: url('./assets/intermarche_landing_bg_mobile.png');
        padding-bottom: 10px;


        .textBlock {
          width: 90%;
          margin: 20px auto;
          color: black;

          .title {
            text-align: right;
            margin-left: 120px;
            position: relative;
            font-size: 26px;
            line-height: 30px;
            margin-bottom: 35px;

            span {
              font-size: 1em;
            }
          }

          .subTitle,
          .paragraph {
            font-size: 16px;
            line-height: 18px;
          }
        }

        .thumb {
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          display: block;
          text-align: center;
        }

        a {
          position: relative;
          max-width: 90%;
          margin-top: 20px;
        }
      }
    }
  }
}